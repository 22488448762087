import { css, useTheme } from '@emotion/react';
import React, { memo, useState } from 'react';
import { AccordionProps } from 'tsconfig/types';
import { CmsRichText } from '../CmsRichText/CmsRichText';
import { ChevronUp, ChevronDown } from '../../../svg';
import { inspectorMode } from 'common-ui';

export const CmsAccordion = memo(({ contentItem }: AccordionProps) => {
  const theme = useTheme();
  const { reference } = contentItem.fields || {};
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index: any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const accordionWrapper = css`
    ${theme.widgets.Search?.accordionWrapper}
  `;

  const schema = (data: any) => {
    const schemaListItems = (data: any) => {
      const getAnswers = (data: any) => {
        return data.content;
      };

      return data?.map((hit: any, index: string | number) => {
        return {
          '@type': 'Question',
          name: hit.fields.entryTitle,
          acceptedAnswer: {
            '@type': 'Answer',
            text: getAnswers(hit?.fields),
          },
        };
      });
    };

    return {
      '@context': 'http://schema.org',
      '@type': 'FAQPage',
      mainEntity: schemaListItems(data),
    };
  };

  return (
    <>
      {reference && (
        <div>
          <div css={accordionWrapper}>
            {reference.map((item: any, index) => (
              <div className="accordion-item" key={index} onClick={() => handleClick(index)}>
                <div className="accordion-title" {...inspectorMode(item.sys.id, 'entryTitle')}>
                  <h3 className="title">{item.fields && item.fields.entryTitle}</h3>
                  <div className="accordion-icon">{activeIndex === index ? <ChevronUp /> : <ChevronDown />}</div>
                </div>
                <div className={`accordion-content ${activeIndex === index ? 'show-accordion' : ''}`}>
                  <CmsRichText contentItem={item} key={index} />
                </div>
              </div>
            ))}
          </div>

          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema(reference)) }} />
        </div>
      )}
    </>
  );
});
