import { Skeleton } from '@mantine/core';
import { css, useTheme } from '@emotion/react';

const BannerSkeleton = () => {
  const theme = useTheme();
  const bannerSkeleton = css`
    ${theme.widgets.CmsBanner?.bannerSkeleton}
  `;

  return (
    <div css={bannerSkeleton} data-test="BannerSkeleton">
      <div className="text-section">
        <Skeleton height={70} width="90%" radius="sm" />
        <Skeleton height={20} width="80%" radius="sm" mt={16} />
        <Skeleton height={20} width="50%" radius="sm" mt={16} />
        <Skeleton height={20} width="40%" radius="sm" mt={24} />
        <div className="cta-section">
          <Skeleton height={56} width="120px" radius="md" mt={32} />
          <Skeleton height={56} width="120px" radius="md" mt={32} />
        </div>
      </div>
      <div className="image-section">
        <Skeleton height={500} width={1000} radius="sm" />
      </div>
    </div>
  );
};

export default BannerSkeleton;
