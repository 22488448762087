import { css, useTheme } from '@emotion/react';
import React, { memo, useMemo } from 'react';
import { CmsImage } from '../CmsImage/CmsImage';
import { ArticleEntryType } from 'tsconfig/types';
import { CmsRichText } from '../CmsRichText/CmsRichText';
import { inspectorMode } from 'common-ui';
import getPixalImageDataUrl from '../../../utils/getPixalImageDataUrl';

export type CmsArticleProps = {
  contentItem: ArticleEntryType;
};

export const CmsArticle: React.FC<CmsArticleProps> = memo(({ contentItem: { fields, sys } }) => {
  const formattedDate = useMemo(() => {
    if (fields?.publishedDate) {
      const date = new Date(fields.publishedDate);
      const day = date.getDate();
      const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
      const year = date.getFullYear();
      return `${day} ${month}, ${year}`;
    }
    return '';
  }, [fields?.publishedDate]);

  const theme = useTheme();
  const wrapper = css`
    ${theme.widgets?.CmsArticle?.wrapper}
  `;

  return (
    <div css={wrapper}>
      <a href={fields.link}>
        <CmsImage
          disableAutoSize={false}
          width={500}
          height={300}
          isNextImg
          contentItem={fields.media}
          placeholder="blur"
          blurDataURL={getPixalImageDataUrl('#CCC')}
        />
      </a>
      <div className="article-container">
        {fields?.secondaryHeader && (
          <div className="secondary eyebrowSmall" {...inspectorMode(sys.id, 'secondaryHeader')}>
            {fields.secondaryHeader}
          </div>
        )}
        <a href={fields.link}>
          <h2 {...inspectorMode(sys.id, 'primaryHeader')}>{fields.primaryHeader}</h2>
        </a>
        {fields?.publishedDate && (
          <div className="date utilityLarge" {...inspectorMode(sys.id, 'publishedDate')}>
            {formattedDate}
          </div>
        )}
        {fields?.content && (
          <div className="article-content">
            <CmsRichText contentItem={fields.content} />
          </div>
        )}
      </div>
    </div>
  );
});
