import { css, useTheme } from '@emotion/react';
import { NavLink } from '@mantine/core';
import {
  CmsContentSection,
  CmsImage,
  DrawerMenu,
  Icon,
  MobileMenuNavItem,
  MobileMenuTopLevelNode,
  inspectorMode,
  parseMenuDataFromContentfulMegaNav,
} from 'common-ui';
import get from 'lodash/get';
import Link from 'next/link';
import React, { memo, useMemo, useState } from 'react';
import {
  ArrayEntryType,
  ConditionalContainerEntryType,
  DLClickEventHandlerProps,
  ImageEntryType,
  LinkEntryType,
  NavigationEntryType,
  SiteEntryType,
  TabEntryType,
  TabsEntryType,
} from 'tsconfig/types';
import NavItem from '../../../GlobalFooter/NavItem';
import { TypeBasedConditionalContent } from '../../../contentful/CmsConditionalContainer/TypeBasedConditionalContent';
import RenderLevel1Menu from './RenderLevel1Menu';

export type MainNavMobileProps = {
  cmsMainNav?: ArrayEntryType<TabsEntryType>;
  footerNavBar?: SiteEntryType['fields']['footerNavBar'];
  socialLinks?: ArrayEntryType<LinkEntryType>;
  badgeImages?: ArrayEntryType<LinkEntryType>;
  dlClickEventHandler: DLClickEventHandlerProps;
};

export const MainNavMobile = memo(
  ({ cmsMainNav = [], footerNavBar, socialLinks, badgeImages, dlClickEventHandler }: MainNavMobileProps) => {
    const [showLevel2Menu, setShowLevel2Menu] = useState(false);
    const [currentLevel2MenuContent, setCurrentLevel2MenuContent] = useState<string>();
    const [stickyContent, setStickyContent] = useState<LinkEntryType[]>();
    const theme = useTheme();
    const menuContainer = css`
        ${theme.widgets.DrawerMenu?.mobileNavMenuContainer}
      `,
      level1MenuPane = css`
        left: ${showLevel2Menu ? '-100%' : 0};
      `,
      level2And3MenuPane = css`
        left: ${showLevel2Menu ? 0 : '100%'};
      `;

    const backArrow = () => {
      setShowLevel2Menu(false);
      setCurrentLevel2MenuContent(undefined);
    };

    const renderLevel1Menu = (fields: any) => {
      return (
        <RenderLevel1Menu
          fields={fields}
          setStickyContent={setStickyContent}
          dlClickEventHandler={dlClickEventHandler}
          cb={(val: string) => onShowLevel2Menu(val)}
        />
      );
    };

    //Render the tabs
    const renderMegaNavTabs = useMemo(() => {
      const tabs = (cmsMainNav as TabsEntryType[]).map((tabs: TabsEntryType) => {
        const {
          fields: { entryTitle },
        } = tabs;

        return (
          <TypeBasedConditionalContent<TabsEntryType>
            content={tabs}
            contentTypes={['tabs']}
            key={entryTitle}
            fallback={(content: TabsEntryType) => renderLevel1Menu(content.fields)}
          >
            <div>{renderLevel1Menu(tabs.fields)}</div>
          </TypeBasedConditionalContent>
        );
      });
      return tabs;
    }, [cmsMainNav]);

    function renderLevel2Menu(menuItems: MobileMenuTopLevelNode) {
      const level2Menu = menuItems?.children?.map(menuItem => {
        if (menuItem.contentType === 'navigation') {
          return (
            <NavLink
              key={menuItem.id}
              className="level2Link"
              label={(menuItem as MobileMenuNavItem).title}
              rightSection={<Icon kind="chevron-down" size="m" />}
              onClick={() => dlClickEventHandler(`${menuItems.title}:${(menuItem as MobileMenuNavItem).title}`)}
              {...inspectorMode(menuItem.id, 'title')}
            >
              {renderLevel3Menu(menuItem as MobileMenuNavItem, menuItems.title)}
            </NavLink>
          );
        } else {
          return (
            <div className="navMenuContent" key={menuItem.id}>
              <CmsContentSection
                contentJsonList={[menuItem.contentItem]}
                dlClickEventHandler={data => {
                  dlClickEventHandler(`${menuItems.title}:${data}`);
                }}
              />
            </div>
          );
        }
      });

      return (
        <>
          <Link prefetch={false} href="#" className="navMenuBackLink" onClick={backArrow}>
            <Icon kind="back" size="xs" /> <span>{menuItems?.title}</span>
          </Link>
          {level2Menu}
        </>
      );
    }

    function onShowLevel2Menu(chosenLevel2Menu: string) {
      setShowLevel2Menu(true);
      setCurrentLevel2MenuContent(chosenLevel2Menu);
    }

    function renderLevel3Menu(level3MenuContent: MobileMenuNavItem, level2MenuContentTitle: string) {
      if (!level3MenuContent) {
        return <div></div>;
      }
      function level3Links() {
        return level3MenuContent?.children?.map((linkContent, index) => {
          return (
            <TypeBasedConditionalContent<LinkEntryType>
              content={linkContent}
              contentTypes={['link']}
              key={linkContent?.fields?.title}
            >
              <a
                key={index}
                href={linkContent?.fields?.url}
                className="level3Link"
                onClick={() =>
                  dlClickEventHandler(
                    `${level2MenuContentTitle}:${level3MenuContent.title}:${linkContent?.fields.title}`,
                  )
                }
                {...inspectorMode(linkContent.sys.id, 'title')}
              >
                {linkContent?.fields?.title}
              </a>
            </TypeBasedConditionalContent>
          );
        });
      }
      return (
        <div>
          {level3Links()}
          {level3MenuContent.url && (
            <a
              href={level3MenuContent.url}
              className="navMenuSectionLink"
              onClick={() =>
                dlClickEventHandler(
                  `${level2MenuContentTitle}:${level3MenuContent.title}:View all ${level3MenuContent.title}`,
                )
              }
              {...inspectorMode(level3MenuContent.id, 'title')}
            >
              View all {level3MenuContent.title}
            </a>
          )}
        </div>
      );
    }

    function onCloseDrawer() {
      //reset menu to root level when closed
      setShowLevel2Menu(false);
      setCurrentLevel2MenuContent(undefined);
    }

    function renderLevel2MenuContentData() {
      let lvl2Content = null;
      (cmsMainNav as (TabsEntryType | ConditionalContainerEntryType)[]).forEach(group => {
        const type = get(group, 'sys.contentType.sys.id', '');
        const conditionContainerContents = get(group, 'fields.content', []);
        if (type === 'conditionalContainer') {
          return conditionContainerContents.forEach((content: TabsEntryType) => {
            const isTabs = get(content, 'sys.contentType.sys.id', null) === 'tabs';
            if (isTabs) {
              return (content.fields.tabPanes as TabEntryType[])?.forEach(tab => {
                if (tab.sys.id === currentLevel2MenuContent) {
                  lvl2Content = tab;
                }
                return null;
              });
            } else {
              return null;
            }
          });
        } else {
          return ((group as TabsEntryType).fields.tabPanes as TabEntryType[])?.forEach((tab: TabEntryType) => {
            if (tab.sys.id === currentLevel2MenuContent) {
              lvl2Content = tab;
            }
            return null;
          });
        }
      });

      if (lvl2Content) {
        const menuData = parseMenuDataFromContentfulMegaNav(lvl2Content);
        return renderLevel2Menu(menuData);
      } else {
        return null;
      }
    }

    return (
      <div data-test="widget-MainNavMobile" className="header-mainnav-mobile">
        <DrawerMenu
          position="left"
          target={<Icon kind="hamburger-menu" size="l" />}
          onClose={onCloseDrawer}
          className="navigation-link"
          isAccessibleFor="notDesktop"
          stickyContent={stickyContent}
        >
          <div css={menuContainer}>
            <div css={level1MenuPane} className="level1MenuPane">
              <div className="menu-container">{renderMegaNavTabs}</div>
              <div>
                <div className="mainnav-mobile-drawer-social-panel">
                  {(socialLinks as unknown as LinkEntryType[])?.map(({ fields }, index: number) => (
                    <div key={index}>
                      <Link style={{ borderBottom: 'none' }} prefetch={false} href={fields.url ?? ''}>
                        <Icon kind={fields.title} size="m" data-test={fields.title} />
                      </Link>
                    </div>
                  ))}
                </div>
                <div className="mainnav-mobile-drawer-footer-links-panel">
                  <div>
                    {(footerNavBar as NavigationEntryType[])?.map(({ fields }, index: number) => (
                      <NavItem key={index} fields={fields} />
                    ))}
                  </div>
                </div>
                <div className="mainnav-mobile-drawer-badges-panel">
                  {(badgeImages as unknown as LinkEntryType[])?.map(({ fields }, index: number) => {
                    const iconImage = fields.icon && (
                      <CmsImage isNextImg contentItem={fields.icon as ImageEntryType} disableAutoSize key={index} />
                    );
                    if (fields.url) {
                      return (
                        <div key={index}>
                          <Link prefetch={false} href={fields.url ?? ''}>
                            {iconImage}
                          </Link>
                        </div>
                      );
                    }
                    return iconImage;
                  })}
                </div>
              </div>
            </div>
            <div css={level2And3MenuPane} className="level2And3MenuPane">
              <div>{currentLevel2MenuContent && renderLevel2MenuContentData()}</div>
            </div>
          </div>
        </DrawerMenu>
      </div>
    );
  },
);
