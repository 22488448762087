import { globals } from './globals';
import { CURRENCIES, CURRENCY_SYMBOL } from './constants';

export function formatCurrency(amount: number, groupSeparator = ',', decimalSeparator = '.') {
  const symbol = globals.currencyCode !== CURRENCIES.GBP ? CURRENCY_SYMBOL.USD : CURRENCY_SYMBOL.GBP;
  const pattern = amount < 0 ? '-Cn' : 'Cn';
  let formattedAmount = decimalSeparator ? Math.abs(amount).toFixed(2) : Math.floor(Math.abs(amount)).toString();
  if (decimalSeparator !== '' && decimalSeparator !== '.') {
    formattedAmount = formattedAmount.replace('.', decimalSeparator);
  }
  formattedAmount = formattedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, groupSeparator);
  return pattern.replace('n', formattedAmount).replace('C', symbol);
}
