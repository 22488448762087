import React from 'react';
import { css, useTheme } from '@emotion/react';

export const Loading = () => {
  const theme = useTheme();
  const widgetTheme = theme.widgets.Loading || {};
  const wrapper = css`
      display: flex;
      justify-content: center;
      align-items: center;
      ${widgetTheme.wrapper}
    `,
    spinner = css`
      ${theme.mixins.animateSpin}
      ${theme.mixins.spinnerBorder}
      ${theme.mixins.roundedFull}
      width: 28px;
      height: 28px;
      border-width: 4px;
      border-color: ${theme.colors.primary1};
      border-right-color: transparent;
      ${widgetTheme.spinner}
    `,
    spinnerText = css`
      ${theme.mixins.visuallyHidden}
      ${widgetTheme.spinnerText}
    `;

  return (
    <div data-test="loading-spinner" className="loaderContainer" css={wrapper}>
      <div css={spinner}>
        <span css={spinnerText}>Loading...</span>
      </div>
    </div>
  );
};
