import { userStatus } from 'common-state';
import { isBrowser } from 'common-state/src/helpers/utils';
import {
  AnalyticPageInfo,
  AnalyticUserInfo,
  UserDetails,
  DLClickEventProps,
  UserPermissions,
  DLBasketUpdateProps,
} from 'tsconfig/types';
import { globals } from '../utils/globals';
import { DL_EVENTS_CONFIGURATION } from './AdobeDataLayerEvents';

// import { mockUser } from 'common-state/src/atoms/userMockData';

// This is the list of events that are allowed to be pushed to the data layer in embedded
const WHITE_LIST_EVENTS = [
  'mainNavClick',
  'topNavClick',
  'cartIcon',
  'search',
  'loginButton',
  'logout',
  'notYou',
  'phoneClick',
  'liveChatInteraction',
  'emailClick',
  'accordionMenu',
  'socialShare',
];
interface ExtendedUserDetails extends UserDetails {
  userPermissions: UserPermissions;
}
export class AdobeDataLayer {
  private enableLogger: boolean;

  // Constructor for the AdobeDataLayer class. Initializes the data layer and sets the logger status.
  constructor(enableLog = false) {
    // this.initilizeDL();
    this.enableLogger = enableLog;
  }

  // Getter for the Adobe Data Layer from the window object. Returns an empty array if not available.
  get getWindowDL(): any {
    return isBrowser() && window?.adobeDataLayer ? window?.adobeDataLayer : [];
  }

  pageView(userData: any, pageViewData: any, permissionData: []) {
    const page = this.getPageInfo();
    const user = this.getUserInfo(userData, permissionData);

    this.createEvent(
      {
        ...pageViewData,
        page,
        user,
      },
      'pageView',
    );
  }

  // This method triggers a click event in the Adobe Data Layer.
  // It takes an event name and click object with properties for the click event.
  // It then pass the data to updateOrCreateEvent method.
  clickEvent(event: string, click: DLClickEventProps, additionalData = {}) {
    this.createEvent(
      {
        click,
        ...additionalData,
      },
      event,
    );
  }

  // This method retrieves information about the current page from the window location.
  // It returns an object with properties for the page name, type, hier, country, and brand.
  // If the current path is the root ("/"), it sets the page name, type, and hier to "home".
  // Otherwise, it sets these properties to a cleaned-up version of the path.
  getPageInfo() {
    const path = isBrowser() ? window?.location.pathname : '';
    const page: AnalyticPageInfo = {
      pageName: '',
      pageType: '',
      pageHier: '',
      country: globals.country as string,
      brand: globals.brandId as string,
    };

    if (path === '/') {
      page.pageHier = 'home';
      page.pageName = 'homepage';
      page.pageType = 'home';
    } else if (path.includes('/search') || path.includes('/wine')) {
      page.pageHier = 'product:search results';
      page.pageName = path;
      page.pageType = 'search results';
    } else if (path.includes('/product/')) {
      page.pageHier = 'product:product';
      page.pageName = path;
      page.pageType = 'product';
    } else if (path.includes('/cart')) {
      page.pageHier = 'checkout:shopping cart';
      page.pageName = '/shopping cart';
      page.pageType = 'shopping cart';
    } else if (path.includes('/myaccount/unlimited')) {
      page.pageHier = 'account:unlimited';
      page.pageName = 'account unlimited';
      page.pageType = 'My Account';
    } else {
      const name = path?.replace(/[\W_]+/g, ' ')?.trim();
      page.pageHier = name;
      page.pageName = name;
      page.pageType = name;
    }

    return page;
  }

  getPageType() {
    const pageInfo = adobeDataLayer.getPageInfo();
    return pageInfo.pageType;
  }

  getUserInfo(loginUser?: ExtendedUserDetails, permissionData?: any) {
    if (!isBrowser()) {
      return;
    }
    // const loginUser = mockUser

    const { loginStatus, loginType } = userStatus(loginUser?.securityStatus);

    const setCorrectLoginTypeForAdobe = (loginType: string) => {
      if (loginType === 'hard_logged') {
        return 'Hard Logged-In';
      }
      if (loginType === 'soft_logged') {
        return 'Soft Logged-In';
      }
      return 'unidentified';
    };

    // Defaults
    const user: AnalyticUserInfo = {
      state: loginUser?.shoppingLocationContext?.stateCode || '',
      loginStatus: loginStatus,
      loginType: setCorrectLoginTypeForAdobe(loginType),
      type: loginType !== 'unidentified' ? 'Existing Customer' : 'New Customer',
    };

    /* Push limited user detail if not hard logged in
      and overwrite the default user object */
    if ((loginType === 'soft_logged' || loginType === 'hard_logged') && loginUser?.summaryDetails) {
      user.customerId = loginUser.summaryDetails.brandAccountNumber;
      user.eProfileId = loginUser.summaryDetails.profileId;
      user.salutation = loginUser.summaryDetails.salutation || '';
      user.name = `${loginUser.summaryDetails.firstName} ${loginUser.summaryDetails.lastName}`;
      user.type = 'Existing Customer';
      user.memberships = [];
      user.subscriptions = [];

      if (loginUser?.trustedDetails?.subscriptions && loginUser?.trustedDetails?.subscriptions.length > 0) {
        loginUser.trustedDetails.subscriptions.forEach((sub: any) => {
          // Users Memberships - Unlimited
          // Push wineplan into the subscriptions array
          if (sub?.type === 'WINEPLAN') {
            user.subscriptions.push({
              schemeCode: sub.schemeId,
              type: sub.type,
              startDate: sub.enrollmentFromDateAsString,
              endDate: sub.enrollmentToDateAsString,
              frequency: sub.frequency,
              status: sub.status,
            });
          }

          // Return all memberships if not wineplan
          if (sub?.type !== 'WINEPLAN') {
            user.memberships.push({
              schemeCode: sub.schemeId,
              type: sub.type,
              startDate: sub.enrollmentFromDateAsString,
              endDate: sub.enrollmentToDateAsString,
              status: sub.status,
              frequency: sub.frequency,
            });
          }
        });
      }

      /* Permission */
      if (permissionData) {
        // Create a string of permissions
        const result = permissionData
          .map((item: { code: string; state: string }) => `${item.code}=${item.state}`)
          .join(':');
        user.marketingPermissions = result;
      }
    }

    return user;
  }

  preferenceSavedDLEvent() {
    this.createEvent(
      DL_EVENTS_CONFIGURATION.ACCOUNT_PREFERENCE_SAVED,
      DL_EVENTS_CONFIGURATION.ACCOUNT_PREFERENCE_SAVED.action,
    );
  }

  // Basket update DL for cart page
  basketUpdate(productInformation: DLBasketUpdateProps[]) {
    const basketArray = productInformation.map(({ itemCode, name, webHeadline, skus, cartQuantity }) => ({
      itemCode,
      productName: name,
      webHeadline,
      unitPrice: skus?.[0]?.salePrice,
      qty: cartQuantity,
    }));

    this.createEvent(basketArray, 'basketUpdate', 'basket');
  }

  // This method creates a new event in the data layer.
  private createEvent(eventData: any, event: string, propName = 'eventData') {
    if ((globals?.isEmbedded && !WHITE_LIST_EVENTS.includes(event)) || !isBrowser()) {
      return;
    }

    // If the window data layer is available, it push the data to window data layer otherwise it push the data to temporary data layer.
    if (this.getWindowDL) {
      // if (this.enableLogger && isBrowser()) {
      //   console.log('Pushing to Adobe Data Layer:', { event, [propName]: eventData });
      // }
      window.adobeDataLayer?.push({ event, [propName]: eventData });
    }
  }
}

export const adobeDataLayer = new AdobeDataLayer();
