export const saleTag = (product: any) => {
  let isOnSale = false;
  if (product?.productType === 'Case' && product?.skus.length > 0) {
    isOnSale = product?.skus[0]?.buyersRRP === product?.skus[0]?.salePrice ? false : true;
  } else {
    let hasBCode = false;
    let hasCCode = false;
    let hasTCode = false;

    product?.skus?.forEach((item: { itemCode: any[] }) => {
      const itemCodePrefix = item.itemCode[0];
      if (itemCodePrefix === 'B') {
        hasBCode = true;
      } else if (itemCodePrefix === 'C') {
        hasCCode = true;
      } else if (itemCodePrefix === 'T') {
        hasTCode = true;
      }
    });

    if (hasBCode || hasCCode || hasTCode) {
      isOnSale = true;
    }
  }

  return isOnSale;
};
