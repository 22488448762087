import { useState, useEffect, memo } from 'react';
import { CmsImage, globals, Icon, Rating, saleTag } from 'common-ui';
import Image from 'next/image';
import { CartButton } from '../AddToCart/Button';
import { css, useTheme } from '@emotion/react';
import { ColourField, ImageEntryType } from 'tsconfig/types';
import { formatCurrency } from '../../utils/formatCurrency';
import { productLinkBuilder } from 'common-next/src/components/common-functions';
import { ProductCardSkeleton } from '../Skeleton/ProductCard/ProductCard';

export interface ProductCardProps {
  productName: string;
  productRating?: number;
  productReviewCount?: number;
  productPrice?: number | null;
  productPriceOriginal?: number | null;
  productImage?: string;
  productVintage?: string;
  productCardBackgroundImage?: ImageEntryType;
  productCardBackgroundColor?: ColourField;
  productCardButton?: string;
  productCode: string;
  productCardVariant?: string;
  productType?: string;
  productPricePrefix?: string;
  productNumberOfBottles?: number | null;
  productMemberPrice?: number | null;
  isLoading?: boolean;
}

export const ProductCard = memo((props: ProductCardProps) => {
  const theme = useTheme();
  const {
    productName,
    productRating,
    productReviewCount,
    productPrice,
    productPriceOriginal,
    productPricePrefix,
    productImage,
    productVintage,
    productCardBackgroundImage,
    productCardBackgroundColor,
    productCardVariant,
    productCardButton,
    productCode,
    productType,
    productNumberOfBottles,
    productMemberPrice,
    isLoading,
  } = props || {};

  const [imageURL, setImageURL] = useState('');

  useEffect(() => {
    setImageURL(productImage as string);
  }, [productImage]);

  const handleImageError = () => {
    setImageURL(
      `${globals.publicHost}${globals.defaultImagePath}/${productType === 'Case' ? 'default_case' : 'default_bottle'}.png`,
    );
  };

  const productCard = css`
    ${productCardVariant === 'Full bleed background'
      ? theme.widgets.Product?.productCardFullBleed
      : `${
          productCardVariant === 'Standard product card'
            ? theme.widgets.Product?.productCardStandard
            : theme.widgets.Product?.productCardDetailed
        }`}
  `;

  const isCase = productType === 'Case' ? true : false;

  if (isLoading) {
    return <ProductCardSkeleton />;
  }

  const ProductInfoDefault = () => {
    return (
      <div className="product-card-info">
        {saleTag(productVintage) && (
          <>
            <div className="product-sale-status">
              <Icon kind="sale" size="m" />
              <p className="product-on-sale-text">On Sale</p>
            </div>
          </>
        )}
        <div className="product-info-and-button">
          <div>
            <div className="product-name-box">
              <a href={productLinkBuilder(productName, productVintage, productCode)}>
                <label className="product-name">
                  {productName} {productVintage}
                </label>
              </a>
            </div>
            {productRating || productReviewCount ? (
              <div className="product-rating">
                {productRating && <Rating rating={productRating} />}
                {productReviewCount && <span>({productReviewCount})</span>}
              </div>
            ) : (
              <div className="product-rating"></div>
            )}
            <div className="product-price">
              {productPrice && (
                <>
                  {productPricePrefix || ''}
                  {formatCurrency(productPrice)} each
                </>
              )}
            </div>
          </div>
          {productCardButton && (
            <div className="product-card-button">
              {productCardButton.toLocaleLowerCase() === 'Add to Basket'.toLocaleLowerCase() ? (
                <CartButton itemCode={productCode} qty={'1'} buttonText={productCardButton} />
              ) : (
                <a href={productLinkBuilder(productName, productVintage, productCode)} className="view-product-button">
                  {productCardButton}
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const ProductInfoDetailed = () => {
    return (
      <div className="product-card__info">
        {/* Card info Top */}
        {(productName) && (
          <div className="product-card__info-top">
            <div className="product-name-box">
              <a href={productLinkBuilder(productName, productVintage, productCode)}>
                <label className="product-name h8">
                  {productName} {productVintage}
                </label>
              </a>
            </div>
          </div>
        )}

        {/* Card info Bottom */}
        <div className="product-card__info-bottom">
          <div className="product-price body1">
            {productNumberOfBottles && productNumberOfBottles !== 0 ? (
              <span className="product-card__bottles">
                {productNumberOfBottles} bottle{`${productNumberOfBottles > 1 ? 's :' : ' :'}`}
              </span>
            ) : ''}
            {productPrice != null && (
              <>
                {productPriceOriginal != null && productPriceOriginal !== 0 ? (
                  <s className="product-price__original">{formatCurrency(productPriceOriginal)}</s>
                ) : ''}
                <span className="body1 bold">{formatCurrency(productPrice)}</span>
              </>
            )}
          </div>

          {productMemberPrice && productMemberPrice !== 0 ? (
            <span className="product-card__member-price body1 bold">
              {formatCurrency(productMemberPrice)} Unlimited Member Price
            </span>
          ) : (
            ''
          )}

          {productCardButton && (
            <div className="product-card-button">
              {productCardButton.toLocaleLowerCase() === 'Add to Basket'.toLocaleLowerCase() ? (
                <CartButton
                  itemCode={productCode}
                  qty={'1'}
                  buttonText={`${globals.country === 'us' ? 'Add to cart' : productCardButton}`}
                />
              ) : (
                <a href={productLinkBuilder(productName, productVintage, productCode)} className="view-product-button">
                  {productCardButton}
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div css={productCard} className="CmsProductCard">
      <div className="product-card" data-testid="product-card">
        <div className="product-image-and-info">
          {productCardVariant === 'Full bleed background' ? (
            <div className="product-image-wrapper">
              <div
                className="product-background-image"
                style={{
                  backgroundColor: `${productCardBackgroundColor?.value}`,
                }}
              >
                {productCardBackgroundImage ? (
                  <CmsImage contentItem={productCardBackgroundImage} />
                ) : (
                  <div className="product-image">
                    <Image
                      src={imageURL}
                      alt={productImage || 'Product Image'}
                      className={isCase ? 'product-case-default-image' : 'product-bottle-image'}
                      width="10"
                      height="90"
                      onError={handleImageError}
                      layout="responsive"
                      objectFit="cover"
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <a
              className="product-image-wrapper"
              href={productLinkBuilder(productName, productVintage, productCode)}
              style={{ backgroundColor: `${productCardBackgroundColor?.value}` }}
            >
              <div className="product-image">
                <Image
                  src={imageURL}
                  alt={productName || 'Product Image'}
                  className={isCase ? 'product-case-default-image' : 'product-bottle-image'}
                  width="10"
                  height="10"
                  onError={handleImageError}
                  objectFit="cover"
                />
              </div>
            </a>
          )}

          {productCardVariant === 'Detailed product card' && <ProductInfoDetailed />}
          {productCardVariant !== 'Detailed product card' && <ProductInfoDefault />}
        </div>
      </div>
    </div>
  );
});
