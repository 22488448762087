import React, { useState, useEffect } from 'react';
import { Carousel } from '@mantine/carousel';
import { useTheme } from '@emotion/react';
import { useScreenLayout } from 'common-ui';

interface CmsCarouselProps {
  children: React.ReactNode;
  slidesPerBlock: number;
  slidesToScroll: number;
  dataTestId?: string;
  style?: Record<string, any>;
}

export const CmsCarousel = ({
  slidesPerBlock,
  slidesToScroll,
  children,
  dataTestId = 'cms-carousel',
  style,
}: CmsCarouselProps) => {
  const theme = useTheme();
  const { isMobile } = useScreenLayout();
  const [slideSize, setSlideSize] = useState<string | null>(null);

  useEffect(() => {
    const computedSlideSize = `${100 / slidesPerBlock}%`;
    setSlideSize(computedSlideSize);
  }, []);

  if (slideSize === null) {
    return null;
  }

  const isScrollable = Array.isArray(children) && children.length > (isMobile ? 1 : slidesPerBlock);

  return (
    <Carousel
      withIndicators={isScrollable}
      withControls={isScrollable}
      loop={isScrollable}
      slidesToScroll={isMobile ? 1 : slidesToScroll}
      slideSize={isMobile ? '100%' : slideSize}
      align="start"
      slideGap="md"
      controlsOffset="md"
      styles={style ? style : theme.widgets.CmsCarousel}
      data-testid={dataTestId}
    >
      {children}
    </Carousel>
  );
};

interface CmsCarouselSlideProps {
  children: React.ReactNode;
  dataTestId?: string;
}

export const CmsCarouselSlide = ({ children, dataTestId = 'cms-slide' }: CmsCarouselSlideProps) => {
  return <Carousel.Slide data-testid={dataTestId}>{children}</Carousel.Slide>;
};

CmsCarousel.CmsCarouselSlide = CmsCarouselSlide;
