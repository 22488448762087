import React from 'react';
import { CmsContentSection } from 'common-ui';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { CmsConditionalContainer } from './CmsConditionalContainer';
import { ConditionalContainerEntryType } from 'tsconfig/types';

interface TypeBasedConditionalContentProps<T> {
  content: T | ConditionalContainerEntryType;
  contentTypes: string[];
  children: React.ReactNode;
  fallback?: (content: T) => React.ReactNode;
}

export const TypeBasedConditionalContent = <T,>({
  content,
  contentTypes,
  children,
  fallback,
}: TypeBasedConditionalContentProps<T>) => {
  // get content type and fields from content
  const type = get(content, 'sys.contentType.sys.id', '');
  const fields = get(content, 'fields', []);

  // if contentTypes is matching with the content's type, return children
  if (contentTypes?.includes(type)) {
    return <>{children}</>;
  }

  // if contenttypes is not matching with the content's type, then filter the content and check if it is matching with the content's type
  const allowedContents =
    fields &&
    filter(get(fields, 'content', []), content => contentTypes.includes(get(content, 'sys.contentType.sys.id', '')));

  // if no allowed contents, return null
  if (isEmpty(allowedContents)) {
    return <></>;
  }

  // create new content object with allowed contents
  const data = { ...content, fields: { ...fields, content: allowedContents } };
  // console.log('data', data);

  // if fallback is provided, return fallback
  if (fallback) {
    return (
      <CmsConditionalContainer contentItem={data as unknown as ConditionalContainerEntryType}>
        {data.fields.content.map((content: T) => fallback(content))}
      </CmsConditionalContainer>
    );
  }

  // return content section
  return <CmsContentSection contentJsonList={[data]} />;
};
