//the standard layout used in nearly all pages on the site
import { css, useTheme } from '@emotion/react';
import type { Ref, CSSProperties } from 'react';
import React from 'react';

export type ContainerLayoutProps = {
  /**
   * The CSS `max-width` property
   */
  fluid?: boolean;

  /**
   * The children nodes
   */
  children: React.ReactNode;

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;

  /**
   * custom class
   */
  className?: string;

  /**
   * Custom styling
   */
  style?: CSSProperties;
};

export const ContainerLayout = React.forwardRef(
  ({ className, fluid, style, ...props }: ContainerLayoutProps, ref?: ContainerLayoutProps['ref']) => {
    const theme = useTheme();

    return (
      <div
        ref={ref}
        className={className}
        style={style}
        css={css([theme.mixins?.containerLayout, !fluid && theme.mixins?.containerLayoutFixed])}
      >
        {props.children}
      </div>
    );
  },
);

export default ContainerLayout;
