import { BPKey, css, SerializedStyles, Theme } from '@emotion/react';

/**
 * [export description]
 *
 * @param   {T}           props  [props description]
 * @param   {keyof Theme} key    [key description]
 * @param   {Theme}       theme  [Emotion Theme]
 *
 * @return  {<T><props>}         [return description]
 */
export function generateUtilCss<T>(props: T, key: keyof Theme, theme: Theme) {
  const styleObject = theme[key] as Record<string, string>;

  type PropKey = keyof T;

  if (props && typeof props === 'object') {
    return Object.keys(theme['breakpoints']).map(
      bp => props[bp as PropKey] && styleObject[`${key}-${bp}-${props[bp as PropKey]}`],
    );
  } else if (typeof props === 'string') {
    return styleObject[`${key}-${props}`];
  }
}

/**
 * [export description]
 *
 * @param   {Theme}             theme  [Emotion Theme]
 *
 * @return  {SerializedStyles}         [return description]
 */
export function generateGrid(theme: Theme): SerializedStyles | null {
  if (!theme?.mixins) {
    return null;
  }

  return css`
    .grid {
      ${theme.mixins.grid}
      &.no-gutter {
        ${theme.mixins.noGutter}
      }
    }
    .col {
      ${theme.mixins.col}
    }
    ${Object.entries(theme.col).map(([key, value]) => `.${key} { ${value} }`)}
    ${Object.entries(theme.align).map(([key, value]) => `.${key} { ${value} }`)}
    ${Object.entries(theme.justify).map(([key, value]) => `.${key} { ${value} }`)}
    ${Object.entries(theme.offset).map(([key, value]) => `.${key} { ${value} }`)}
  `;
}

/**
 * [export description]
 *
 * @param   {Theme[]}  breakpoints  [breakpoints description]
 * @param   {BPKey}    cb           [cb description]
 *
 * @return  {[]}                    [return description]
 */
export function formQuery(breakpoints: Theme['breakpoints'], cb: (bp: BPKey) => string): string[] {
  return Object.keys(breakpoints).map(
    (breakpoint): string => `
      ${breakpoints[breakpoint as BPKey]} {
        ${cb(breakpoint as BPKey)}
      }
    `,
  );
}

export function formQueryString(breakpoints: Theme['breakpoints'], cb: (bp: BPKey) => string): string {
  return formQuery(breakpoints, cb).join(' ');
}

/**
 * [export description]
 *
 * @param   {Theme[]}      grid   [grid description]
 * @param   {keyof}        key    [key description]
 * @param   {BPKey}        bp     [bp description]
 *
 * @return  {T}                   [return description]
 */
export function getValues<T>(grid: Theme['grid'], key: keyof Theme['grid'], bp: BPKey): T {
  return grid[key][bp] as unknown as T;
}

/**
 * [export description]
 *
 * @param   {Theme[]}      grid   [grid description]
 * @param   {keyof}        key    [key description]
 * @param   {BPKey}        bp     [bp description]
 *
 * @return  {T}                   [return description]
 */
export function getToPxValue<T>(grid: Theme['grid'], key: keyof Theme['grid'], bp: BPKey, defaultValue?: T): T {
  let value = getValues(grid, key, bp);
  if (value && typeof value === 'number') {
    value = `${value}px`;
  }
  return (value || defaultValue) as T;
}

/**
 * [export description]
 *
 * @param   {Theme[]}      grid   [grid description]
 * @param   {keyof}        key    [key description]
 * @param   {BPKey}        bp     [bp description]
 *
 * @return  {T}                   [return description]
 */
export function getMarginValue<T>(grid: Theme['grid'], key: keyof Theme['grid'], bp: BPKey, defaultValue?: T): T {
  let value = getValues(grid, key, bp);
  if (value && typeof value === 'number') {
    value = `${value / 2}px`;
  }
  return (value || defaultValue) as T;
}

/**
 * [export description]
 *
 * @param   {Theme}        theme  [Emotion Theme]
 *
 * @return  {Breakpoints}         [return description]
 */
export function getBreakpoint(theme: Theme): BPKey {
  let screen: BPKey | undefined;

  if (typeof window !== 'undefined' && window.matchMedia) {
    Object.entries(theme.breakpoints).map(
      ([key, value]) => window.matchMedia(value.replace('@media ', '')).matches && (screen = key as BPKey),
    );
  }
  return screen as BPKey;
}
