import { css, useTheme } from '@emotion/react';
import Grid from '../../grid/Grid';
import { BannerProps } from 'tsconfig/types';
import styled from '@emotion/styled';
import { CmsImage } from '../CmsImage/CmsImage';
import { CmsLink } from '../CmsLink/CmsLink';
import { inspectorMode } from 'common-ui';
import { fontCorrectionInRichText } from '../../../utils/fontCorrectionInRichText';
import { BannerWithContentAndCTA } from '../../BannerComponents/BannerComponentWithContentAndCta';
import { BannerWithButton } from '../../BannerComponents/BannerComponentWithButton';
import {
  getClassNameBasedOnVariant,
  getContentWidth,
  getDataTestId,
  getTintedBackground,
  hasTintedBackground,
} from '../../BannerComponents/BannerUtility';
import getPixalImageDataUrl from '../../../utils/getPixalImageDataUrl';
import SkeletonBanner from '../../Skeleton/Banner/Banner';
import { useEffect, useState } from 'react';
import { navigateToBannerLink, stopBannerContentEventPropagation } from './CmsBannerUtility';

export const CmsBanner = ({ contentItem }: BannerProps) => {
  const {
    variant = '',
    CTALink = [],
    contentBackgroundColour = '',
    bannerContent = '',
    alignment = '',
    image,
    backgroundImage,
    contentWidth = '',
    minimumBannerHeight,
    bannerLink,
  } = contentItem.fields || {};

  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const mediaSm = theme.breakpoints.sm ? theme.breakpoints.xs.replace('@media', '') : '';
  const mediaMd = theme.breakpoints.md ? theme.breakpoints.md.replace('@media', '') : '';
  const backgroundImageUrl = mediaMd
    ? backgroundImage?.fields?.largeDeviceImage?.fields?.file?.url
    : backgroundImage?.fields?.smallDeviceImage?.fields?.file?.url;

  const isCenterTintedBackground = variant === 'Full Background - Tinted Centre';
  const bannerContentWidth = getContentWidth(contentWidth);
  const tintedBackground = hasTintedBackground(variant);

  useEffect(() => {
    if (bannerContent) {
      setIsLoading(false);
    }
  }, [bannerContent]);

  const wrapper = css`
    ${theme.widgets.CmsBanner?.default};
    margin-bottom: 10px;
    .banner {
      padding: 0;
      ${minimumBannerHeight && `min-height: ${minimumBannerHeight}px`};
      ${bannerLink && `cursor: pointer`};
      ${getTintedBackground(variant, backgroundImageUrl, theme.breakpoints.sm)}
      .banner-image-wrapper {
        ${tintedBackground
          ? `
            @media (max-width: 768px) {
              padding: 20px 0px 20px 0px;
              display: flex;
              justify-content: center;
              max-height: 400px;
            }`
          : `background: url(${backgroundImageUrl});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;`}
        min-height: 300px;
        min-width: 100%;
        position: relative;
        ${theme.breakpoints.xs} {
          min-width: auto;
        }
        .banner-imgcom {
          position: relative;
          z-index: 2;
        }
        .banner-bg-img {
          position: absolute;
          z-index: -1;
          height: 100%;
          width: 100%;
        }
      }
      .banner-content-wrapper {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        ${tintedBackground
          ? ''
          : `background: ${
              contentBackgroundColour
                ? (theme.colors.bgColors as unknown as Record<string, string>)[contentBackgroundColour]
                : 'initial'
            };
        background-color: ${contentBackgroundColour};`}
        text-align: ${alignment.toLowerCase()};
        .banner-info {
          .banner-button {
            justify-content: ${alignment.toLowerCase()};
          }
        }
      }
    }
  `;

  const widgetTheme = theme.widgets.CmsBanner || {};
  const Section = styled.section`
    ${widgetTheme.wrapper}
  `;

  {
    isLoading && <SkeletonBanner />;
  }

  if (variant === 'Full width background banner with Calls to Action') {
    return <BannerWithButton contentItem={contentItem} />;
  } else {
    return (
      <div
        css={wrapper}
        data-test="banner"
        data-testid={`banner-${getDataTestId(variant)}`}
        onClick={() => {
          navigateToBannerLink(bannerLink);
        }}
      >
        <Grid justify="start" className="banner">
          {!isCenterTintedBackground && (
            <Grid.Col
              md={contentWidth ? 12 - bannerContentWidth : 6}
              xs={12}
              className={`banner-image-wrapper ${getClassNameBasedOnVariant(variant, false)}`}
            >
              {image && (
                <div className="banner-imgcomp">
                  <CmsImage contentItem={image} />
                </div>
              )}
              {!hasTintedBackground(variant) && backgroundImage ? (
                <div className="banner-bg-img">
                  <CmsImage
                    contentItem={backgroundImage}
                    // define sizes to load image to specific size and for width less then 768 it will load image of 100vh
                    // ${((12-bannerContentWidth) / 12) * 100}vw will provide image column size in vh
                    sizes={`${mediaSm}: ${((12 - bannerContentWidth) / 12) * 100}vw, 100vh`}
                    fill
                    isNextImg
                    blurDataURL={getPixalImageDataUrl(contentBackgroundColour)}
                    placeholder="blur"
                  />
                </div>
              ) : null}
            </Grid.Col>
          )}

          <Grid.Col
            md={bannerContentWidth}
            xs={12}
            className={`banner-content-wrapper ${getClassNameBasedOnVariant(variant, true)}`}
          >
            <div data-testid={`banner-info-${alignment.toLowerCase()}`} className="banner-info">
              <Section
                data-test="contentful-textbox"
                data-testid={`banner-content-${bannerContentWidth}`}
                className="banner-content"
              >
                {bannerContent && /<[a-z][\s\S]*>/i.test(bannerContent) && (
                  <div
                    onClick={stopBannerContentEventPropagation}
                    dangerouslySetInnerHTML={{ __html: fontCorrectionInRichText(bannerContent, theme.fonts) }}
                    {...inspectorMode(contentItem?.sys?.id, 'bannerContent')}
                  />
                )}
              </Section>
              {CTALink.length > 0 && (
                <div className="banner-button">
                  {CTALink.map((value: any, index: number) => (
                    <CmsLink key={index} contentItem={value} />
                  ))}
                </div>
              )}
            </div>
          </Grid.Col>
        </Grid>
      </div>
    );
  }
};

CmsBanner.BannerWithContentAndCTA = BannerWithContentAndCTA;
