import { css, useTheme } from '@emotion/react';
import React, { memo } from 'react';
import { globals } from '../../../../utils/globals';
import { handleDLClickEvent } from '../../../../data-layer/handleDLClickEvent';

export const HeaderLogo = memo(({ isSticky = false }: any) => {
  const theme = useTheme();
  const wrapper = css`
    ${theme.widgets.GlobalHeader?.headerLogo}
  `;
  const media = theme.breakpoints.lg ? theme.breakpoints.lg.replace('@media', '') : '';
  const desktopImage = isSticky ? theme.assets.stickyHeaderLogoImgUrl : theme.assets.headerLogoImgUrl;
  const mobileImage = isSticky ? theme.assets.stickyHeaderLogoImgUrl : theme.assets.headerLogoMobileImgUrl;

  return (
    <div data-test="widget-HeaderLogo" css={wrapper} id="header-logo">
      {/* Using Anchor tag instead of Link Component because when clicking on Logo, App will be redirected to the ATG Homepage. 
      In the future when Home page is migrated fully to NextJS app, Link Component can be used */}
      <a
        href="/"
        data-event="headerLogoClick"
        data-section="header:logo"
        data-action="logo"
        data-name="header logo"
        data-text="logo"
        onClick={() => {
          handleDLClickEvent(globals.brandId as string, 'topNavClick');
        }}
      >
        <picture>
          <source media={media} srcSet={desktopImage} />
          <img src={mobileImage} alt="Logo" />
        </picture>
      </a>
    </div>
  );
});
