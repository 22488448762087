export const DL_EVENTS_CONFIGURATION = {
  ACCOUNT_PREFERENCE_SAVED: {
    action: 'accountPreferences',
    clickLocation: 'my account preferences',
    clickName: 'my account select preferences',
    clickAction: 'select preferences',
    clickText: 'select preferences',
    clickDescription: 'account:wine prefences:select preference',
  },
};
