import { TrendingItems } from '@algolia/recommend-react';
import recommend from '@algolia/recommend';
import { css, useTheme } from '@emotion/react';
import Slider from 'react-slick';
import { SlickLeftArrow } from 'common-ui/src/components/SlickLeftArrow/SlickLeftArrow';
import { SlickRightArrow } from 'common-ui/src/components/SlickRightArrow/SlickRightArrow';
import React, { useEffect, useMemo, useState } from 'react';
import { TrendingItem } from './TrendingItem';
import { Icon, globals } from 'common-ui';
import { LoadingOverlay } from '@mantine/core';
import { cartErrorProps } from 'tsconfig/types';

const sliderSettings = {
  className: 'slick-slider-container',
  infinite: false,
  dots: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  arrows: true,
  prevArrow: <SlickLeftArrow />,
  nextArrow: <SlickRightArrow />,
  responsive: [
    { breakpoint: 1199, settings: { slidesToShow: 3, slidesToScroll: 1, arrows: true } },
    { breakpoint: 991, settings: { slidesToShow: 2, slidesToScroll: 1 } },
    { breakpoint: 820, settings: { slidesToShow: 2, slidesToScroll: 1 } },
    { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
  ],
};

const minicartSliderSettings = {
  className: 'slick-slider-container',
  infinite: false,
  dots: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  prevArrow: <SlickLeftArrow />,
  nextArrow: <SlickRightArrow />,
  responsive: [
    { breakpoint: 1199, settings: { slidesToShow: 2, slidesToScroll: 1, arrows: true } },
    { breakpoint: 820, settings: { slidesToShow: 2, slidesToScroll: 1, arrows: true } },
    { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
  ],
};

const ListView = React.memo(
  ({ items, itemComponent, isMiniCart }: { items: any[]; itemComponent: any; isMiniCart: boolean }) => {
    const tempSliderSetting = isMiniCart ? minicartSliderSettings : sliderSettings;
    return (
      <>
        <Slider {...tempSliderSetting}>
          {items.map(item => (
            <div key={item.objectID} className="item">
              {React.createElement(itemComponent, { item })}
            </div>
          ))}
        </Slider>
      </>
    );
  },
);

const TrendingProductItem = React.memo(
  ({
    item,
    handleError,
    setLoading,
    isMiniCart,
    setIsGreatPick,
  }: {
    item: any;
    handleError: (error: boolean, errorMessage: string) => void;
    setLoading: (val: boolean) => void;
    isMiniCart: boolean;
    setIsGreatPick?: (val: boolean) => void;
  }) => {
    return (
      <TrendingItem
        item={item}
        handleError={handleError}
        setLoading={setLoading}
        isMiniCart={isMiniCart}
        setIsGreatPick={setIsGreatPick}
      />
    );
  },
);

export const TrendingProductItems: React.FC<{
  isMiniCart?: boolean;
  setIsGreatPick?: (val: boolean) => void;
}> = ({ isMiniCart = false, setIsGreatPick }) => {
  const recommendClient = useMemo(
    () => recommend((globals?.algoliaAppId || '') as string, (globals?.algoliaSearchKey || '') as string),
    [],
  );
  const indexName = (globals?.algoliaIndexName || '') as string;

  const theme = useTheme();
  const cartErrorWrapper = css`
    ${theme.widgets.Cart.cartError}
  `;
  const slickSlider = useMemo(
    () => css`
      ${theme.widgets.SlickSlider?.wrapper}
    `,
    [theme],
  );
  const productItemTrending = useMemo(
    () => css`
      ${theme.widgets.Cart?.productItemsTrending}
    `,
    [theme],
  );

  const [cartError, setCartError] = useState<cartErrorProps>({ error: false, errorMessage: '' });
  const [loading, setLoading] = useState(false);

  const handleError = (error: boolean, errorMessage: string) => {
    setCartError({ error, errorMessage });
  };

  useEffect(() => {
    if (cartError.error) {
      const timeoutId = setTimeout(() => setCartError({ error: false, errorMessage: '' }), 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [cartError]);

  const HeaderComponent = () => {
    return (
      <>
        <span className="title-overline"></span>
        <p className={isMiniCart ? 'mini-cart-title' : 'auc-Recommend-title'}>
          {isMiniCart ? (globals.country === 'us' ? 'May We Recommend' : 'Top Trending Wines') : 'Trending'}
        </p>
      </>
    );
  };

  return (
    <div css={[slickSlider, productItemTrending]} style={{ position: 'relative' }}>
      <LoadingOverlay visible={loading} zIndex={1000} loaderProps={{ color: theme.colors.primary1 }} />
      {cartError.error && (
        <div css={cartErrorWrapper}>
          <Icon kind="information-circle" size="xs" />
          {cartError.errorMessage}
        </div>
      )}
      <TrendingItems
        recommendClient={recommendClient}
        indexName={indexName}
        queryParameters={{
          filters: 'inStock:true',
        }}
        itemComponent={props => (
          <TrendingProductItem
            {...props}
            handleError={handleError}
            setLoading={setLoading}
            isMiniCart={isMiniCart}
            setIsGreatPick={setIsGreatPick}
          />
        )}
        view={props => {
          return <ListView {...props} isMiniCart={isMiniCart} />;
        }}
        maxRecommendations={5}
        headerComponent={props => {
          return props?.recommendations?.length > 0 ? <HeaderComponent /> : <></>;
        }}
      />
    </div>
  );
};
