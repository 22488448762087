import { atom, selector } from 'recoil';
import { effectConsoleLogAtomUpdates } from '../helpers/debug';
import { WinePlanTypes } from 'tsconfig/types';

// Storing the upcomming case details in wine plan screen
export const winePlanUpcomingCaseAtom = atom<WinePlanTypes>({
  key: 'winePlanUpcomingCaseAtom', // It saves the upcoming case details data
  default: undefined,
  effects: [effectConsoleLogAtomUpdates],
});

//Selectors
//----------------------------------------------------------------------

export const selectUpcomingCaseData = selector({
  key: 'selectUpcomingCaseData',
  get: ({ get }) => {
    try {
      const upcomingCaseData = get(winePlanUpcomingCaseAtom);
      if (!!upcomingCaseData) {
        return upcomingCaseData;
      } else {
        return undefined;
      }
    } catch (error) {
      throw new Error('Something went wrong');
    }
  },
});
