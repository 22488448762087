import { useTheme } from '@emotion/react';
import { css } from '@emotion/css';
import { Carousel } from '@mantine/carousel';
import { useScreenLayout, createLogger, globals } from 'common-ui';
import { memo, useEffect, useState } from 'react';
import { initCommerceApiWithGlobals } from 'common-api';
import { ProductCard } from '../ProductCard/ProductCard';
import { setProductType } from 'common-next/src/components/common-functions';

export const RecentlyViewedProducts = memo(() => {
  const log = createLogger();
  const theme = useTheme();
  const { isMobile } = useScreenLayout();
  const [isLoading, setIsLoading] = useState(true);

  const [recentlyBrowsed, setRecentlyBrowsed] = useState<{ userItems: { product: any }[] }>({ userItems: [] });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const commerceApi = initCommerceApiWithGlobals();
        const data = await commerceApi.User.getRecentlyBrowsed();
        setRecentlyBrowsed(data.response);
        setIsLoading(false);
      } catch (error) {
        log.error('Error fetching RecentlyViewedProducts:', error);
      }
    };

    fetchData();
  }, []);

  const recentlyViewed = css`
    ${theme.widgets.Product?.recentlyViewed}
  `;

  return (
    <div data-testid="recently-viewed" className={`no-print ${recentlyViewed}`}>
      {recentlyBrowsed && recentlyBrowsed?.userItems && recentlyBrowsed?.userItems.length > 0 && (
        <div className="container">
          <h5 data-testid="recently-viewed-title">Recently Viewed</h5>
          <Carousel
            withIndicators
            loop
            slidesToScroll={isMobile ? 1 : 5}
            slideSize={isMobile ? '100%' : '20%'}
            align="start"
            slideGap="md"
            controlsOffset="md"
            className="recently-viewed-carousel"
            styles={theme.widgets.CmsCarousel}
            data-testid={'recently-viewed-carousel'}
          >
            {recentlyBrowsed?.userItems.map(item => (
              <Carousel.Slide key={item.product?.id}>
                <ProductCard
                  productName={item.product?.name}
                  productRating={item.product?.averageOverallRating}
                  productReviewCount={item.product?.totalReviewCount}
                  productPrice={item.product?.skus?.[0]?.buyersRRP}
                  productImage={`${globals.publicHost}${item.product?.smallImage || item.product?.largeImage}`}
                  productCardButton={`View ${setProductType(item.product?.productType, item.product?.isMixed, null)}`}
                  productCode={item.product?.itemCode}
                  productType={item.product?.mixed ? 'Case' : item.product?.productType ?? ''}
                  isLoading={isLoading}
                />
              </Carousel.Slide>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
});
