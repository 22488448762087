import { BPKey, Theme } from '@emotion/react';
import { Align, Breakpoints, Justify, PartialRecord, ThemeGrid } from 'tsconfig/types';
import { getValues } from './grid.utils';

export const generateBreakPoints = (bp: PartialRecord<Breakpoints, number>): Theme['breakpoints'] =>
  Object.assign({}, ...Object.entries(bp).map(([key, value]) => ({ [key]: `@media (min-width: ${value}px)` })));

const alignItemsValues: Record<Align, string> = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  baseline: 'baseline',
  stretch: 'stretch',
  unset: 'unset',
};

const justifyContentValues: Record<Justify, string> = {
  center: 'center',
  end: 'flex-end',
  start: 'flex-start',
  between: 'space-between',
  around: 'space-around',
  evenly: 'space-evenly',
  unset: 'unset',
};

export const generateAlign = (breakpoints: Record<Breakpoints, string>) => {
  return Object.assign(
    {},
    ...Object.entries(breakpoints)
      .map(([bp, query]) =>
        Object.entries(alignItemsValues).map(([name, value]) => ({
          [`align-${bp}-${name}`]: `${query} {
                align-items: ${value}
              }`,
        })),
      )
      .flat(1),
    ...Object.entries(alignItemsValues).map(([name, value]) => ({ [`align-${name}`]: `align-items: ${value}` })),
  );
};

export const generateJustify = (breakpoints: Record<Breakpoints, string>) => {
  return Object.assign(
    {},
    ...Object.entries(breakpoints)
      .map(([bp, query]) =>
        Object.entries(justifyContentValues).map(([name, value]) => ({
          [`justify-${bp}-${name}`]: `${query} {
                justify-content: ${value}
              }`,
        })),
      )
      .flat(1),
    ...Object.entries(justifyContentValues).map(([name, value]) => ({
      [`justify-${name}`]: `justify-content: ${value}`,
    })),
  );
};

export const generateOffset = (breakpoints: Record<Breakpoints, string>, grid: ThemeGrid) => {
  return Object.assign(
    {},
    ...Object.entries(breakpoints)
      .map(([bp, query]) => {
        const colsLength = getValues<number>(grid, 'columns', bp as BPKey);
        let i = 0;
        const styles = [];
        while (i < colsLength) {
          const value = i / colsLength;
          styles.push({
            [`offset-${bp}-${i}`]: `${query} {
                        margin-left: ${value === 0 ? 0 : value * 100 + '%'};
                      }`,
          });
          i++;
        }
        return styles;
      })
      .flat(1),
  );
};

export const generateCol = (breakpoints: Record<Breakpoints, string>, grid: ThemeGrid) => {
  return Object.assign(
    {},
    ...Object.entries(breakpoints)
      .map(([bp, query]) => {
        const colsLength = getValues<number>(grid, 'columns', bp as BPKey);
        let i = 1;
        const styles = [];
        while (i <= colsLength) {
          styles.push({
            [`col-${bp}-${i}`]: `${query} {
                  flex: 0 0 auto;
                  width: ${(i / colsLength) * 100}%;
                }`,
          });
          i++;
        }
        styles.push({
          [`col-${bp}-auto`]: `${query} {
                flex: 0 0 auto;
                width: auto;
              }`,
        });
        return styles;
      })
      .flat(1),
  );
};
