/**
 * Theme for Laithwaites AU site
 */
import { Theme } from '@emotion/react';
import { buildTheme } from 'common-ui';
import merge from 'lodash/merge';
import { icons } from 'common-font-icons';
import { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types';
import { buildThemeMixinsLW, buildThemeVarsLW, buildThemeWidgetStylesLW } from 'dw-uk-law/theme';

export const buildThemeVarsAPACLW = (vars: Pick<Theme, CommonThemeVars>) => {
  const lwPink = '#E2004D';
  const lwPink500 = '#E2004D';
  const lwOceanGreen = '#009F8E';
  const lwGrey700 = '#616161';
  const lwUnlimitedMemberPrice = '#009F8E';
  const lwCTAGreen = '#117B53';
  const lwUnlimitedMemberText = '#009F8E';
  const unlimitedPopupbackground = '#112B4B';
  const lwlightRed = '#FBE6F1';
  const lwJoinWhite = '#FFFFFF';
  const lwBodyBlack = '#222222';

  const colors = {
    primary1: lwPink,
    primary5: lwlightRed,
    primary6: lwOceanGreen,
    btnsWhiteBg: lwJoinWhite,
    textPrimaryBg: lwPink500,
    textSecondaryBg: lwGrey700,
    textUnlimitedPrice: lwUnlimitedMemberPrice,
    textUnlimitedBg: lwUnlimitedMemberText,
    unltPopupBackground: unlimitedPopupbackground,
    btnsPrimaryBg: lwCTAGreen,
    lwJoinWhite: lwJoinWhite,
    lwBodyBlack: lwBodyBlack,
  };
  const fonts = {
    sizeH5: '18px',
    weightMediumPlus: '600',
  };

  const lwThemeVars = buildThemeVarsLW(vars);

  return merge({}, lwThemeVars, {
    meta: {
      name: 'Laithwaites AU',
    },
    icons,
    colors,
    fonts,
  });
};
export const buildThemeMixinsAPACLW = (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
  return buildThemeMixinsLW(vars);
};
export const buildThemeWidgetStylesAPACLW = (vars: Omit<Theme, 'widgets'>) => {
  const buildThemeWidget = buildThemeWidgetStylesLW(vars);
  return merge({}, buildThemeWidget, {
    Search: {
      hitWrapper: ` 
      ${buildThemeWidget.Search.hitWrapper}
      .ais-Highlight-nonHighlighted, .country, .web-description, .review-count,.hit-content {
        color: ${vars.colors.grey50};
      }
      &.list-view {
        .ais-Hits-list {
          grid-gap: 0px;
          .ais-Hits-item {
            gap: 0;
            &:first-child {
              border-top: 1px solid #C4C7C5;
              ${vars.breakpoints.xs} {
                margin-top: 16px;
              }
              @media (min-width: 767px) {
                margin-top: inherit;
              }
              ${vars.breakpoints.sm} {
                margin-top: inherit;
              }
            }
            ${vars.breakpoints.xs} {
              grid-template-columns: none;
              border-radius: 8px;
              border: 1px solid #C4C7C5;
              margin-bottom: 16px;
            }
            @media (min-width: 767px) {
              grid-template-columns: 350px 366px;
              border: none;
              border-radius: 0;
              border-bottom: 1px solid #DBDBDB;
              margin: 0;
              padding: 16px 0 !important;
            }
            ${vars.breakpoints.sm} {
              grid-template-columns: 350px 366px;
              border: none;
              border-radius: 0;
              border-bottom: 1px solid #DBDBDB;
              margin: 0;
              padding: 16px 16px 16px 0 !important;
            }
            ${vars.breakpoints.md} {
              grid-template-columns: 350px 354px;
            }
            ${vars.breakpoints.lg} {
              grid-template-columns: 293px auto;
            }
            ${vars.breakpoints.xl} {
              grid-template-columns: 430px auto;
            }
            ${vars.breakpoints.xxl} {
              grid-template-columns: 618px auto;
            }

            .unlimited-icon {
              i{
                font-size: ${vars.fonts.sizeH7};
              }
            }
            .image-bg {
              padding: 0;
              .image-container {
                margin: 4px;
                .offer {
                  .stock {
                    bottom: 10px;
                    background: ${vars.colors.textPrimaryBg};
                  }
                  svg {
                    ${vars.breakpoints.xs} {
                      height: 38px;
                      width: 44px;
                      left: 10px;
                      top: 10px;
                    }
                    ${vars.breakpoints.sm} {
                      height: 38px;
                      width: 44px;
                      left: 10px;
                      top: 10px;
                    }
                    ${vars.breakpoints.md} {
                      height: 38px;
                      width: 44px;
                      left: 10px;
                      top: 10px;
                    }
                    ${vars.breakpoints.lg} {
                      height: 38px;
                      width: 44px;
                      left: 10px;
                      top: 10px;
                    }
                    ${vars.breakpoints.xl} {
                      height: 64px;
                      width: 55px;
                      left: 16px;
                      top: 16px;
                    }
                    ${vars.breakpoints.xxl} {
                      height: 64px;
                      width: 55px;
                      left: 16px;
                      top: 16px;
                    }
                  }
                }
              }
            }

            .hit-content {
              ${vars.breakpoints.xs} {
                padding: 12px 16px;
              }
              @media (min-width: 767px) {
                padding: 0 0px 16px 24px;
                text-align: left;
              }
              ${vars.breakpoints.sm} {
                padding: 0 0px 16px 24px;
                text-align: left;
              }
              ${vars.breakpoints.md} {
                padding: 0 0 16px 24px;
                text-align: left;
              }
              ${vars.breakpoints.lg} {
                padding: 0px 24px 16px 24px;
                text-align: left;
              }
              ${vars.breakpoints.xl} {
                padding: 32px 0px 32px 72px;
                text-align: left;
              }
              ${vars.breakpoints.xxl} {
                padding: 32px 0px 32px 72px;
                text-align: left;
              }
              margin-bottom: 0;
              .title {
                font-family: ${vars.fonts.familyTertiary};
                font-weight: ${vars.fonts.weightMediumPlus};
                line-height: 31px;
                color: ${vars.colors.lwBodyBlack};
                margin-bottom: 16px;
                ${vars.breakpoints.xs} {
                  font-size: ${vars.fonts.sizeH5};
                }
                @media (min-width: 767px) {
                  font-size: ${vars.fonts.sizeH5};
                }
                ${vars.breakpoints.lg} {
                  font-size: ${vars.fonts.sizeH5};
                }
                ${vars.breakpoints.xl} {
                  font-size: ${vars.fonts.sizeH9};
                }
              }
              .inline-container {
                font-family: ${vars.fonts.familySecondary};
                ${vars.breakpoints.xs} {
                  margin: 12px 0;
                  font-size: ${vars.fonts.sizeH5};
                }
                @media (min-width: 767px) {
                  justify-content: start;
                  margin: 16px 0;
                }
                ${vars.breakpoints.sm} {
                  justify-content: start;
                  margin: 16px 0;
                }
                .country-rating {
                  margin: 0;
                  .country {}
                  .rating {
                    ${vars.breakpoints.xs} {
                      margin-top: 8px;
                    }
                    @media (min-width: 767px) {
                      justify-content: start;
                      margin-top: 16px;
                    }
                    ${vars.breakpoints.sm} {
                      justify-content: start;
                      margin-top: 16px;
                    }
                  }
                }
              }
              .web-description {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                
                font-family: ${vars.fonts.familySecondary};
                font-weight: ${vars.fonts.weightNormal};
                font-size: ${vars.fonts.sizeLarge};
                line-height: 24px;
                ${vars.breakpoints.xs} {
                  margin: 12px 0;
                }
                @media (min-width: 767px) {
                  margin: 16px 0;
                }
                ${vars.breakpoints.sm} {
                  margin: 16px 0;
                }
              }
              .pricing-panel-container {
                .top-price-section {
                  padding-top: 0;
                  margin-bottom: 8px;
                  @media (max-width: 1024px) {
                    margin-bottom: 4px;
                  }
                  .price-section {
                    .case-sku-savings-sales-price {
                      font-family: ${vars.fonts.familyTertiary};
                      font-weight: ${vars.fonts.weightMediumPlus};
                      line-height: 26.5px;
                      color: ${vars.colors.textPrimaryBg};
                      ${vars.breakpoints.xs} {
                        font-size: ${vars.fonts.sizeH7};
                      }
                      @media (min-width: 767px) {
                        font-size: ${vars.fonts.sizeH5};
                      }
                      ${vars.breakpoints.lg} {
                        font-size: ${vars.fonts.sizeH5};
                      }
                      ${vars.breakpoints.xl} {
                        font-size: ${vars.fonts.sizeH8};
                      }
                    }
                    .case-sku-sales-price {
                      ${vars.breakpoints.xs} {
                        font-size: ${vars.fonts.sizeH7};
                      }
                      @media (min-width: 767px) {
                        font-size: ${vars.fonts.sizeH5};
                      }
                      ${vars.breakpoints.xl} {
                        font-size: ${vars.fonts.sizeH5};
                      }
                      ${vars.breakpoints.xxl} {
                        font-size: ${vars.fonts.sizeH8};
                      }
                    }
                    .strike-case-sku-pricing-panel {
                      font-family: ${vars.fonts.familySecondary};
                      font-weight: ${vars.fonts.weightNormal};
                      font-size: ${vars.fonts.sizeLarge};
                      line-height: 22.5px;
                      color: ${vars.colors.textSecondaryBg};
                      align-self: center;
                      margin-left: 8px;
                    }
                  }
                  .save-pill {
                    padding: 3px 5px;
                    font-family: ${vars.fonts.familySecondary};
                    font-weight: ${vars.fonts.weightBold};
                    font-size: ${vars.fonts.sizeSmall};
                    line-height: 18px;
                    color: ${vars.colors.textPrimaryBg};
                    border-radius: 30px;
                  }
                }
                .case-details-savings {
                  padding-top: 0;
                  margin-bottom: 16px;
                  text-align: left;
                }
                .case-details-isUnlimited {
                  padding-top: 0;
                  margin-bottom: 16px;
                  text-align: left;
                }
                .case-details {
                  padding-top: 0;
                  font-family: ${vars.fonts.familySecondary};
                  font-weight: ${vars.fonts.weightNormal};
                  line-height: 22.5px;
                  color: ${vars.colors.textPrimaryBg};
                  text-align: left; 
                  ${vars.breakpoints.xs} {
                    margin-bottom: 12px;
                  }
                  @media (min-width: 767px) {
                    margin-bottom: 16px;
                    font-size: ${vars.fonts.sizeDefault};
                  }
                  ${vars.breakpoints.sm} {
                    margin-bottom: 16px;
                    font-size: ${vars.fonts.sizeDefault};
                  }
                  ${vars.breakpoints.md} {
                    font-size: ${vars.fonts.sizeLarge};
                  }
                  ${vars.breakpoints.lg} {
                    font-size: ${vars.fonts.sizeLarge};
                  }
                  ${vars.breakpoints.xl} {
                    font-size: ${vars.fonts.sizeLarge};
                  }
                  ${vars.breakpoints.xxl} {
                    font-size: ${vars.fonts.sizeLarge};
                  }
                }
                .member-price-section-case-sku {
                  .member-price-section-vpp {
                    ${vars.breakpoints.xs} {
                      margin: 12px 0;
                      display: block;
                      text-align: left;
                    }
                    @media (min-width: 767px) {
                      margin: 16px 0;
                      display: flex;
                    }
                    ${vars.breakpoints.sm} {
                      margin: 16px 0;
                      display: flex;
                    }
                  }
                  .member-price-section {
                    ${vars.breakpoints.xs} {
                      margin: 12px 0;
                      display: block;
                      text-align: left;
                    }
                    @media (min-width: 767px) {
                      margin: 16px 0;
                      display: flex;
                    }
                    ${vars.breakpoints.sm} {
                      margin: 16px 0;
                      display: flex;
                    }
                    .member-price {
                      font-family: ${vars.fonts.familySecondary};
                      font-weight: ${vars.fonts.weightMediumPlus};
                      font-size: ${vars.fonts.sizeDefault};
                      line-height: 16px;
                      color: ${vars.colors.textUnlimitedPrice};
                    }
                    .learn-more-isUnlimited {
                      font-family: ${vars.fonts.familySecondary};
                      font-weight: ${vars.fonts.weightNormal};
                      font-size: ${vars.fonts.sizeDefault};
                      line-height: 17px;
                      color: ${vars.colors.lwBodyBlack};
                    }
                  }
                }
                .straight-sku-container {
                  .vpp-price {
                    margin-top: 0;
                    text-align: left;
                    ${vars.breakpoints.xs} {
                      margin-bottom: 12px;
                      font-size: ${vars.fonts.sizeDefault};
                    }
                    @media (min-width: 767px) {
                      margin-bottom: 16px;
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.sm} {
                      margin-bottom: 16px;
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    .price {
                      ${vars.breakpoints.xs} {
                        font-size: ${vars.fonts.sizeH7};
                      }
                      @media (min-width: 767px) {
                        font-size: ${vars.fonts.sizeH5};
                      }
                      ${vars.breakpoints.lg} {
                        font-size: ${vars.fonts.sizeH5};
                      }
                      ${vars.breakpoints.xl} {
                        font-size: ${vars.fonts.sizeH8};
                      }
                    }
                  }
                  .top-price-section {
                    padding-top: 0;
                    ${vars.breakpoints.xs} {
                      margin-bottom: 12px;
                    }
                    @media (min-width: 767px) {
                      margin-bottom: 16px;
                    }
                    ${vars.breakpoints.sm} {
                      margin-bottom: 16px;
                    }
                    .price-section-sku {
                      font-family: ${vars.fonts.familySecondary};
                      font-weight: ${vars.fonts.weightNormal};
                      font-size: ${vars.fonts.sizeLarge};
                      line-height: 22.5px;
                      color: ${vars.colors.lwBodyBlack};
                      .price {
                        font-family: ${vars.fonts.familyTertiary};
                        font-weight: ${vars.fonts.weightMediumPlus};
                        line-height: 26.5px;
                        color: ${vars.colors.lwBodyBlack};
                        ${vars.breakpoints.xs} {
                          font-size: ${vars.fonts.sizeH7};
                        }
                        @media (min-width: 767px) {
                          font-size: ${vars.fonts.sizeH5};
                        }
                        ${vars.breakpoints.lg} {
                          font-size: ${vars.fonts.sizeH5};
                        }
                        ${vars.breakpoints.xl} {
                          font-size: ${vars.fonts.sizeH8};
                        }
                      }
                    }
                  }
                }
                .addToCart-QuantitySelector {
                  .add-to-cart-section {
                    padding-top: 0;
                  }
                  .add-to-cart-btn-container {
                    .cart-button {
                      margin: 0;
                      button {
                        padding: 12px 24px;
                        font-family: ${vars.fonts.familySecondary};
                        font-weight: ${vars.fonts.weightMediumPlus};
                        font-size: ${vars.fonts.sizeDefault};
                        line-height: 16px;
                        color: ${vars.colors.lwJoinWhite};
                      }
                    }
                  }
                }
                .vpp-btn {
                  margin-top: 0;
                  .separation-line {
                    margin: 20px 0;
                  }
                  .cart-button {
                    margin: 0;
                    button {
                      padding: 12px 24px;
                      font-family: ${vars.fonts.familySecondary};
                      font-weight: ${vars.fonts.weightMediumPlus};
                      font-size: ${vars.fonts.sizeDefault};
                      line-height: 16px;
                      color: ${vars.colors.lwJoinWhite};
                      ${vars.breakpoints.xs} {
                        padding: 12px;
                      }
                      @media (min-width: 767px) {
                        padding: 12px 24px;
                      }
                      ${vars.breakpoints.sm} {
                        padding: 12px 24px;
                      }
                    }
                  }
                  .member-price-section-vpp {
                    ${vars.breakpoints.xs} {
                      display: block;
                      text-align: left;
                      margin-top: 12px;
                    }
                    @media (min-width: 767px) {
                      display: flex;
                      margin-top: 8px;
                    }
                    ${vars.breakpoints.sm} {
                      display: flex;
                      margin-top: 8px;
                    }
                  }
                  .member-price-section {
                    ${vars.breakpoints.xs} {
                      display: block;
                      text-align: left;
                      margin-top: 12px;
                    }
                    @media (min-width: 767px) {
                      display: flex;
                      margin-top: 8px;
                    }
                    ${vars.breakpoints.sm} {
                      display: flex;
                      margin-top: 8px;
                    }
                    .member-price {
                      font-family: ${vars.fonts.familySecondary};
                      font-weight: ${vars.fonts.weightMediumPlus};
                      font-size: ${vars.fonts.sizeDefault};
                      line-height: 16px;
                      color: ${vars.colors.textUnlimitedPrice};
                    }
                    .learn-more-isUnlimited {
                      font-family: ${vars.fonts.familySecondary};
                      font-weight: ${vars.fonts.weightNormal};
                      font-size: ${vars.fonts.sizeDefault};
                      line-height: 17px;
                      color: ${vars.colors.lwBodyBlack};
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.grid-view { 
        .ais-Hits-list {
          .ais-Hits-item {
            .image-bg {
              height: 244px;
              display: flex;
              flex-direction: column;
              padding: 0;

              .image-container {
                padding: 12px 16px;
                position: relative;
                height: 100%;

                .image {
                  margin: 0;
                  img {
                    position: relative !important;
                    height: 220px !important;
                  }
                }

                .offer {
                  display: flex;
                  flex-direction: column;
                  position: absolute;
                  left: 16px;
                  top: 12px;
                  bottom: 12px;
                  svg {
                    top: 0;
                    left: 0
                  }

                  .stock {
                    z-index: 1;
                    left: -6px;
                    bottom: 0;
                    width: 77px;
                    ${vars.breakpoints.xxl} {
                      left: 0;
                    }
                    ${vars.breakpoints.xl} {
                      left: 0;
                    }
                  }
                }

                .quick-links-container {
                  bottom: 15px;
                }
              }
            }

            .hit-content {
              padding: 24px 16px 0;
              justify-content: start;

              .title {
                min-height: 50px;
                letter-spacing: -0.22px;
                ${vars.breakpoints.md} {
                  min-height: 62px;
                  font-size: 15px;
                }
                ${vars.breakpoints.lg} {
                  min-height: 62px;
                  font-size: ${vars.fonts.sizeH4};
                }
                ${vars.breakpoints.xl} {
                  min-height: 62px;
                }
                ${vars.breakpoints.xxl} {
                  min-height: 62px;
                  font-size: ${vars.fonts.sizeH8};
                }
              }

              .case {
                position: unset;
                width: 100%;
                transform: none;
                padding: 2px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                color: ${vars.colors.lwBodyBlack};
                ${vars.breakpoints.md} {
                  display: -webkit-box !important;
                  margin: 0 !important;
                }
                ${vars.breakpoints.lg} {
                  display: -webkit-box !important;
                  margin: 0 !important;
                }
                ${vars.breakpoints.xl} {
                  display: -webkit-box;
                  margin: 14.5px 0 16px !important;
                }
                ${vars.breakpoints.xxl} {
                  margin: 14.5px 0 16px !important;
                  display: -webkit-box;
                }
              }

              .web-description {
                height: auto;
                min-height: auto;
                margin: 14.5px 0 16px;
                padding: 0;
                font-size: ${vars.fonts.sizeH7};
                color: ${vars.colors.lwBodyBlack};
                ${vars.breakpoints.md} {
                  font-size: ${vars.fonts.sizeDefault};
                }
                ${vars.breakpoints.lg} {
                  font-size: ${vars.fonts.sizeDefault};
                  display: none;
                }
                ${vars.breakpoints.xl} {
                  font-size: ${vars.fonts.sizeDefault};
                  display: -webkit-box;
                  height: 39px;
                }
                ${vars.breakpoints.xxl} {
                  font-size: ${vars.fonts.sizeH4};
                  height: 44px;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              .inline-container {
                display: block;
                font-style: normal;

                .country-rating {
                  display: block;
                  margin: 0;
                  ${vars.breakpoints.md} {
                    margin-top: 0px;
                  }
                  ${vars.breakpoints.lg} {
                    margin-top: 0px;
                  }
                  ${vars.breakpoints.xl} {
                    margin-top: 8px;
                  }
                  ${vars.breakpoints.xxl} {
                    margin-top: 8px;
                  }

                  .country {
                    margin-bottom: 8px;
                    color: ${vars.colors.lwBodyBlack};
                    @media (max-width: 1024px) {
                      display: flex;
                      flex-direction: column;
                      row-gap: 5px;
                    }
                    ${vars.breakpoints.lg} {
                      margin-bottom: 2px;
                    }

                    .country-container {
                      img {
                        width: auto;
                        margin: 0 !important;
                      }
                    }

                    .grapeVariety-container {
                      ${vars.breakpoints.md} {
                        margin-left: 0;
                      }
                      ${vars.breakpoints.lg} {
                        margin-left: 12px;
                      }
                      ${vars.breakpoints.xl} {
                        margin-left: 12px;
                      }
                      ${vars.breakpoints.xxl} {
                        margin-left: 12px;
                      }
                    }
                  }

                  .rating {
                    margin-top: 0;
                    .review-count {
                      color: ${vars.colors.lwBodyBlack};
                      text-decoration: none;
                      &:hover {
                        text-decoration: none;
                      }
                    }

                    a {
                      cursor: pointer;
                      text-decoration: none;
                      &:hover {
                        text-decoration: none;
                      }
                    }
                  }
                }

                .reserveTodayContainer {
                  margin: 0 auto!important;
                  width: 100%;
                  > div {
                    padding: 8px;
                    border-radius: 4px;
                    ${vars.breakpoints.md} {
                      font-size: ${vars.fonts.sizeSmall};
                    }
                    ${vars.breakpoints.xl} {
                      margin: 18.5px 0 24px;
                      font-size: ${vars.fonts.sizeSmall};
                    }
                    ${vars.breakpoints.xxl} {
                      margin: 18.5px 0 28px;
                      font-size: ${vars.fonts.sizeH7};
                    }
                  }

                  .reserve-text {
                    font-weight: 400;
                  }
                  a {
                    text-decoration: underline;
                  }
                }
              }
            }

            .addToCart-container { 
              .price-section {
                margin-bottom: 10px;
                text-align: center;
                line-height: 26px;
                .vpp-price {
                  text-align: left;
                }
                .sku-price {
                  font-family: ${vars.fonts.familyTertiary};
                  font-size: ${vars.fonts.sizeH5};
                  font-weight: ${vars.fonts.weightBold};
                  color: ${vars.colors.grey50};
                  .presell-price {
                    font-family: ${vars.fonts.familySecondary};
                    font-weight: ${vars.fonts.weightBold};
                    ${vars.breakpoints.md} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.lg} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xl} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xxl} { 
                      font-size: ${vars.fonts.sizeH5};
                    }
                  }
                  .presell-price + span {
                    ${vars.breakpoints.md} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.lg} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xl} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xxl} { 
                      font-size: ${vars.fonts.sizeH5};
                    }
                  }

                  .original-price-case {
                    padding: 0 5px;
                    font-size: ${vars.fonts.sizeLarge};
                    &.line-through {
                      text-decoration: line-through;
                      color: ${vars.colors.textSecondaryBg};
                      font-family: ${vars.fonts.familySecondary};
                      font-weight: ${vars.fonts.weightNormal};
                    }
                  }

                  .original-price-bottle {
                    ${vars.breakpoints.md} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.lg} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xl} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xxl} { 
                      font-size: ${vars.fonts.sizeH5};
                    }
                    .pricing-label {
                      font-size: ${vars.fonts.sizeLarge};
                    }
                  }

                  .vpp-price-section {
                    ${vars.breakpoints.md} { 
                      font-size: ${vars.fonts.sizeSmall};
                    }
                    ${vars.breakpoints.lg} { 
                      font-size: ${vars.fonts.sizeDefault};
                    }
                    ${vars.breakpoints.xl} { 
                      font-size: ${vars.fonts.sizeDefault};
                    }
                    ${vars.breakpoints.xxl} { 
                      font-size: ${vars.fonts.sizeDefault};
                    }

                    .sales-price {
                      color: ${vars.colors.textPrimaryBg};
                      font-size: ${vars.fonts.sizeDefault};
                      @media (max-width: 1024px) {
                        font-size: 13px;
                      }
                    }
                  }

                  .sales-price {
                    color: ${vars.colors.textPrimaryBg};
                    ${vars.breakpoints.md} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.lg} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xl} {
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xxl} { 
                      font-size: ${vars.fonts.sizeH5};
                    }
                  }

                  .pricing-label {
                    font-size: ${vars.fonts.sizeLarge};
                    font-weight: ${vars.fonts.weightNormal};
                    font-family: ${vars.fonts.familySecondary};
                  }
                  .mix-price-label {
                    font-size: ${vars.fonts.sizeLarge};
                  }
                  .vpp-price-label {
                    font-size: ${vars.fonts.sizeDefault};
                  }
                }
                .member-price-section-case {
                  margin-bottom: 10px;
                  font-family: ${vars.fonts.familySecondary};
                  font-weight: ${vars.fonts.weightBold};
                  color: ${vars.colors.textUnlimitedPrice};
                  font-size: ${vars.fonts.sizeDefault};

                  .unlimited-icon {
                    padding-right: 7px;
                    font-size: 20px;
                  }
                  .pricing-label {
                    font-weight: ${vars.fonts.weightNormal};
                  }
                  .member-price-label {
                    display: block;
                    ${vars.breakpoints.xxl} { 
                      display:inline;
                    }
                  }
                }

                .member-price-section-bottle {
                  margin-bottom: 10px;
                  
                  font-size: ${vars.fonts.sizeSmall};
                  font-family: ${vars.fonts.familySecondary};
                  font-weight: ${vars.fonts.weightBold};
                  color: ${vars.colors.textUnlimitedPrice};
                  ${vars.breakpoints.lg} {
                    font-size: ${vars.fonts.sizeDefault};
                  }
                  ${vars.breakpoints.xl} {
                    font-size: ${vars.fonts.sizeDefault};
                  }
                  ${vars.breakpoints.xxl} {
                    font-size: ${vars.fonts.sizeDefault};
                  }
                  .unlimited-icon {
                    padding-right: 7px;
                    font-size: 20px;
                  }
                  .pricing-label {
                    font-weight: ${vars.fonts.weightNormal};
                  }
                  .member-price-label {
                    ${vars.breakpoints.md} {
                      font-size: ${vars.fonts.sizeSmall};
                    }
                    ${vars.breakpoints.lg} {
                      font-size: ${vars.fonts.sizeDefault};
                    }
                    ${vars.breakpoints.xl} {
                      font-size: ${vars.fonts.sizeDefault};
                    }
                    ${vars.breakpoints.xxl} {
                      font-size: ${vars.fonts.sizeDefault};
                    }
                  }
                }
              }

              .add-to-cart-btn-container {
                margin: 0 auto;
                ${vars.breakpoints.md} {
                  width: 90%;
                }
                ${vars.breakpoints.lg} {
                  width: 83.5%;
                }
                ${vars.breakpoints.xl} {
                  width: 71%;
                }
                ${vars.breakpoints.xxl} {
                  width: 100%;
                }

                .tooltip-btn {
                  display: inline-block;
                  height: auto;
                  font-size: ${vars.fonts.sizeDefault};
                  border-radius: 5px;
                  line-height: 26px;
                  padding: 12px 24px;
                  border-width: 0;
                  border-style: solid;
                  background-color: ${vars.colors.btnsPrimaryBg};
                  color: ${vars.colors.lwJoinWhite};
                  border-width: 2px;
                  border-color: transparent;
                }
                .top-price-section {
                  padding: 0;
                  .price-section {
                    margin: 0;
                    .pricing-panel-container {
                      .straight-sku-container {
                        gap: 4px;
                        .vpp-price {
                          margin-top: 10px;
                        }
                        .top-price-section {
                          margin-top: 10px;
                          .price-section-sku {
                            font-size: ${vars.fonts.sizeLarge};
                          }
                        }
                      }
                      .addToCart-QuantitySelector {
                        margin-bottom: 24px;
                        .add-to-cart-section {
                          margin-top: 20px;
                          padding-top: 0;
                          .add-to-cart-btn-container {
                            .cart-button {
                              button {
                                font-weight: ${vars.fonts.weightNormal} !important;
                              }
                            }
                            .member-price-section {
                              margin-top: 10px;
                            }
                          }
                        }
                      }
                      .vpp-btn {
                        margin: 25px 0 24px 0;
                        .member-price-section {
                          margin-top: 10px;
                        }
                      }
                    }
                  }
                }
                .view-wine-link {
                  font-size: ${vars.fonts.sizeLarge};
                  margin-bottom: 6px;
                }
              }
            }
          }
        }
      }
      `,
    },
    Product: {
      wrapper: `  
      ${buildThemeWidget.Product.wrapper}
      .about-the-wine-container{
        .auc-Recommend{
          .product-item-container{
            .product-price{
              color:${vars.colors.lwBodyBlack};
            }
          }
        }
      }
      .product-layout{
        .layout-name{
           .sub-title{
            font-weight: 600px;
            font-size: ${vars.fonts.sizeH8};
            line-height: 26.4px;
            font-family: ${vars.fonts.familyTertiary};
           }
            .main-title{
              font-size:48px;
              font-family:${vars.fonts.familyPrimary};
              line-height: 50.4px;
            }
        }
        .layout-details{
          .description-container{
           font-weight: ${vars.fonts.weightSmall};
            font-size: ${vars.fonts.sizeLarge};
            line-height: 22.4px;
            font-family: ${vars.fonts.familySecondary};
          }
          .wineStyle-country-au{
          list-style-type: none; 
          display: flex; 
          align-items: center;
          justify-content:start;
          padding: 10px; 
          margin: 10px; 
          a{
          display: inline-flex;
          align-items: center;
          justify-content: center;
          }
          .wine-glass{
           margin-right:30px;
           padding:5px;
         
          }
          .countryName{
            margin-left:10px;
            
         }
          .countryFlag{
            padding:20px;
         }
        }
        .money-back-guarantee {
          flex-direction: column;
          gap: 10px;
          &.apac {
              justify-content: space-evenly;
              margin-left: -15px;
              margin-right: -15px;
              ${vars.breakpoints.sm} {
                margin-left: 0px;
                margin-right: 0px;
              }
              .inner-container {
                display: flex;
                align-items: center;
                .icon {
                  margin-right: 10px;
                  i {
                    color: #112B4B;
                  }
                }
                .content {
                  .descp {
                    padding-bottom: 0px;
                    color: #112B4B;
                  }
                }
              }
          }
          ${vars.breakpoints.md} {
            flex-direction: row;
            gap: 0px;
          }
        }
      }
      `,
      orderform: ` 
      ${buildThemeWidget.Product.orderform}

      // ************** PRICING PANEL MIXED CASE CSS ************** 
      .top-price-section{
        align-items: flex-start;
        order: 2;
        .price-section.presell{
          font-size: 14px;
          display: block; 
          .sale-price{
            margin-top: 0px;
            .price{
              font-size: 22px;
              font-weight: ${vars.fonts.weightMediumPlus};
            }
          }
          .case-details{
            padding-top: 0px;
            color: ${vars.colors.black};
            font-size: 14px;
          }
        }
        .price-section{
          display: flex;
          align-items: flex-start;
          .case-sku-savings-sales-price, .case-sku-sales-price, .case-sku-savings-sales-price-isUnlimited {
            font-weight: ${vars.fonts.weightMediumPlus};
            font-size: ${vars.fonts.sizeH8};
            font-family: ${vars.fonts.noto};
          }
          .case-sku-savings-sales-price {
            color: ${vars.colors.textPrimaryBg};
          }
          .case-sku-savings-sales-price-isUnlimited {
            color: ${vars.colors.textUnlimitedPrice};
          }
          .strike-case-sku-pricing-panel{
            color: ${vars.colors.textSecondaryBg};
            font-size: ${vars.fonts.sizeLarge};
            margin-left: 5px;
          }
        }
        .save-pill {
          font-weight: ${vars.fonts.weightBold};
          font-size: ${vars.fonts.sizeSmall};
          color: ${vars.colors.textPrimaryBg};
          background-color: ${vars.colors.primary5};
          padding: 0 8px;
          border-radius: 30px;
        }
      }
      .case-details-savings, .case-details, .case-details-isUnlimited{
        color: ${vars.colors.textPrimaryBg};
        font-size: ${vars.fonts.sizeLarge};
        padding-top: 10px;
      }
      .case-details-isUnlimited {
        color: ${vars.colors.textUnlimitedPrice};
      }
      .case-details{
        color: ${vars.colors.black};
      }
      // ************** PRICING PANEL MIXED CASE CSS ENDS ************** 
      
      // ************** COMMON CSS FOR PRICING PANEL ************** 

      .member-price-section, .member-price-section-vpp {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .member-price {
          font-weight: ${vars.fonts.weightMediumPlus};
          color: ${vars.colors.textUnlimitedBg}
        }
      }
      .member-price-section {
        margin-top: 20px;
      }
      // ************** COMMON CSS FOR PRICING PANEL ENDS **************

      // ************** PRICING PANEL STRAIGHT CASE CSS ************** 
      .vpp-btn {
        margin-top: 25px;
        margin-bottom: 0px;
        .cart-button {
          margin: 10px 0px;
        }
      }
      .addToCart-QuantitySelector{
        display: flex;
        ${vars.breakpoints.xs} {
          margin-bottom: ${vars.space.md};
        }
        ${vars.breakpoints.sm} {
          margin-bottom: 0px;
        }
        
        .add-to-cart-btn-container{
          flex-grow: 1;
        }
      }
      .add-to-cart-section{
        flex-grow: 1;
        .customProdQantityField {
          & div:first-child{
            margin-right: 0px;
          }
        }
      }
     
        .customProdQantityField {
          
          .input-group-prepend .btn-minus, .input-group-append .btn-plus{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 1px solid ${vars.colors.textSecondayBg};
            background-color:${vars.colors.white};
            color: ${vars.colors.grey50};
          }
          .quantity{
            color: ${vars.colors.grey50};            
          }
          
        }

        .straight-sku-container{
          display:flex;
          flex-direction: column;
          .vpp-price {
            color: ${vars.colors.primary1};
          }
        }

      .top-price-section, .vpp-price, .vpp-price-isUnlimited-member {
        .price-section-sku, .first-price-head {
          display: inline;
          .price {
            font-family: ${vars.fonts.familyTertiary};
            font-weight: ${vars.fonts.weightMediumPlus};
            font-size: ${vars.fonts.sizeH8};
            line-height: 26.4px;
          }
        }
      }
      .vpp-price, .vpp-price-isUnlimited-member {
        &.first-price-head{ 
          .price {
            font-size: ${vars.fonts.sizeH8};
            font-family: ${vars.fonts.noto};
            font-weight: ${vars.fonts.weightMediumPlus};
          }
        }
      }
      .vpp-price-isUnlimited-member {
        color: ${vars.colors.textUnlimitedBg};
        order: 1;
        text-align: left;
        ${vars.breakpoints.xs} {
          margin-bottom: 12px;
        }
        @media (min-width: 767px) {
          margin-bottom: 16px;
        }
        ${vars.breakpoints.sm} {
          margin-bottom: 16px;
        }
      }
      // ************** PRICING PANEL STRAIGHT CASE CSS ************** 
      `,
      learnMoreWrapper: `
        .mantine-Overlay-root {
          z-index: 600;
        }
        .mantine-Modal-body {
          height: 100% !important;
          width: 100% !important;
          .modalTitle {
            font-family: ${vars.fonts.familyPrimary};
            font-size: 30px;
            font-weight: 500;
            line-height: 33px;
            text-align: center;
            display: flex;
            margin: auto;
            justify-content: center;
            color: ${vars.colors.white};
            margin: 12px !important;
            @media (max-width: 480px) {
              font-size: 25px;
            }
          }
          .modalTitle2 {
            text-transform: none;
            margin-bottom: 25px !important;
            display: flex;
            margin: auto;
            justify-content: center;
            font-family: ${vars.fonts.familyTertiary};
            font-size: 18px;
            font-weight: 600px;
            line-height: 25.2px;
            text-align: center;
            color: ${vars.colors.white};
            @media (max-width: 480px) {
              font-size: 16px;
            }
          }
          .divider {
            margin-bottom: 36px;
          }
          .description {
            margin-bottom: 36px;
            font-family: ${vars.fonts.familySecondary};
          }
          .incentives {
            margin-bottom: 20px;
            font-size: 14px;
            font-weight: 400px;
            font-family: ${vars.fonts.familySecondary};
          }
        }
        background-color: ${vars.colors.unltPopupBackground};
        .mantine-Modal-inner {
          .mantine-Paper-root {
            max-width: 450px !important;
            @media (max-width: 480px) {
              max-width: 375px !important;
            }
          }
          z-index: 1000;
          .learnMore-header {
            margin-top: 10px;
            color: ${vars.colors.white};
            background-color: ${vars.colors.unltPopupBackground};
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            width: 100%;
            .unlimited-img {
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
            }
            .learnMore-closeBtn {
              margin-left: auto;
            }
          }
          .termsAndCondContent {
            height: 200px;
            overflow-y: scroll;
            scrollbar-width: none;
            &::-webkit-scrollbar {
             display: none; 
            }
          }
          .join-unlimited {
            width: 191px;
            height: 44px;
            background-color: ${vars.colors.white};
            border-radius: 0px;
            color: black;
            .loadIcon {
              display: flex;
              align-items: center;
              justify-content: center;
              .addingText {
                margin-left: 30px;
              }
            }
          }
          .termsAndCond {
            color: ${vars.colors.white};
            max-height: 200px;
            overflow-y: auto;
            margin-top: 10px;
            font-size: 16px;
            font-weight: 500px;
          }
          .termsAndCondContent {
            margin-top: 10px;
            font-size: 0.8rem;
          }

          .mantine-Paper-root {
            background-color: ${vars.colors.unltPopupBackground};
            .mantine-Text-root {
              color: ${vars.colors.white};
            }
          }
        }
      `,
    },
    Cart: {
      MiniCart: `
      ${buildThemeWidget.Cart.MiniCart}
      .mini-cart-header {
        i {
          color:rgb(0, 110, 26);
        }
        .mini-cart-header-text{
          font-weight: 500;
          line-height: 33px;
          font-size: 30px;
        }
      }
      .product-great-pick{
        font-weight: ${vars.fonts.weightMediumPlus};
        letter-spacing: -0.01em;
      }
      .product-detail-text {
        letter-spacing: -0.01em;
        font-weight: ${vars.fonts.weightMediumPlus};
      }

      .product-link {
        font-weight: ${vars.fonts.weightMediumPlus};
      }
      .price-section {
        font-weight: ${vars.fonts.weightMediumPlus};
      }
      .price-section .strike+.sale-price{
        color: ${vars.colors.primary1}
      }
      .price-section .strike+.member-price {
        color:${vars.colors.primary6};
      }

      .price-section .price {
        font-weight: ${vars.fonts.weightMediumPlus};
      }

      .member-savings {
        color: ${vars.colors.primary6};
        font-weight: ${vars.fonts.weightMediumPlus};
      }
    `,
    },
  });
};

const createTheme: ThemeCreateFunction = () =>
  buildTheme(buildThemeVarsAPACLW, buildThemeMixinsAPACLW, buildThemeWidgetStylesAPACLW);

export default createTheme;
