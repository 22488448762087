export class DwChat {
  open() {
    // This id (element) and the chat widget is setup via GTM. Element id: hackathon-widget-button
    const chatWidgetElement = document.getElementById('hackathon-widget-button');

    // Trigger a click on the chat widget element, when the footer Link is clicked.
    if (chatWidgetElement) {
      chatWidgetElement.click();
    }
  }
}

export const dwChat = new DwChat();
