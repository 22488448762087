import { useState } from 'react';

type defaultImageType = {
  imageSrc: string;
  error: boolean;
  handleImageError: () => void;
};

export const useDefaultImageHandling = (initialSrc: string, fallbackSrc: string): defaultImageType => {
  const [imageSrc, setImageSrc] = useState(initialSrc || '');
  const [error, setError] = useState(false);

  const handleImageError = () => {
    setImageSrc(fallbackSrc);
    setError(true);
  };

  return { imageSrc, error, handleImageError };
};
