/* eslint-disable @typescript-eslint/no-var-requires */
import aa from 'search-insights';

import React, { createElement, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { GetSourcesParams, OnSelectParams } from '@algolia/autocomplete-core';
import {
  autocomplete,
  AutocompleteApi,
  AutocompleteCollection,
  AutocompleteOptions,
  AutocompleteRenderer,
  AutocompleteSource,
  AutocompleteState,
  getAlgoliaResults,
  HTMLTemplate,
} from '@algolia/autocomplete-js';
import { productLinkBuilder } from 'common-next/src/components/common-functions';

import { createRedirectUrlPlugin } from '@algolia/autocomplete-plugin-redirect-url';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { RecentSearchesItem } from '@algolia/autocomplete-plugin-recent-searches/dist/esm/types';
import '@algolia/autocomplete-theme-classic/dist/theme.min.css';
import { css, useTheme } from '@emotion/react';

import { render } from 'react-dom';
import { useHierarchicalMenu, useSearchBox } from 'react-instantsearch';

import { globals } from '../../utils/globals';
import { Button } from '../Button/Button';
import Grid from '../grid/Grid';
import { Icon } from '../Icon/Icon';
import { searchClientConfig } from './algoliaSearchConfig';
import { ProductItem } from './templates';
import { useRecoilValue, selectUserDetails, selectedStateAtom } from 'common-state';
import { handleDLClickEvent } from '../../data-layer/handleDLClickEvent';

export declare type BaseItem = Record<string, unknown>;
export interface AlgoliaSearchProps extends Partial<AutocompleteOptions<BaseItem>> {
  onCancelSearch?: () => void;
  isSticky?: boolean;
  setSearchOverLay: () => number;
}

export interface TransformSourceParams {
  source: AutocompleteSource<RecentSearchesItem>;
  state: AutocompleteState<RecentSearchesItem>;
  onRemove(id: string): void;
  onTapAhead(item: RecentSearchesItem): void;
}

declare global {
  interface Window {
    aa: (action: string, userToken: string) => void;
    dataLayer: any[];
  }
}

export interface ExtendedAlgoliaSearchResult extends BaseItem {
  skuItemCodes?: string[] | undefined;
  productName?: string;
  threeTierStates?: string[] | undefined;
  wineryDirectStates?: string[] | undefined;
}

export default function AlgoliaSearch({
  onCancelSearch,
  isSticky,
  setSearchOverLay,
  ...autocompleteProps
}: AlgoliaSearchProps) {
  const [suggestionPanelTop, setSuggestionPanelTop] = useState<number>(0);
  const panelRef = useRef<HTMLDivElement>(null);
  // const { algoliaAppId, algoliaSearchKey } = globals as Record<string, string>;
  const autocompleteContainer = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const autocompleteInstance = useRef<AutocompleteApi<BaseItem> | null>(null);
  const resultsCustomConfig = () => {
    if (globals.country === 'us') {
      return {
        hitsPerPage: 20,
        viewAllItemsLength: 4,
      };
    }
    return {
      hitsPerPage: 10,
      viewAllItemsLength: 9,
    };
  };
  const userDetails = useRecoilValue(selectUserDetails);
  const currentState = useRecoilValue(selectedStateAtom);

  const { query } = useSearchBox();

  const [instantSearchUiState, setInstantSearchUiState] = useState({ query });

  const { items: categories } = useHierarchicalMenu({
    attributes: ['hierarchicalCategories.lvl0', 'hierarchicalCategories.lvl1'],
  });

  const currentCategory = useMemo(() => categories.find(({ isRefined }) => isRefined)?.value, [categories]);

  const wrapper = css`
    ${theme.widgets.Search?.searchBoxWrapper}
  `;
  const autocompletePanel = css`
    ${theme.widgets.Search?.autocompletePanel}
  `;
  const searchClient = searchClientConfig();

  /**
   * Logic to send user to either Endeca or Algolia Search based on
   * the platform the search is rendered on
   */
  const redirectToEndecaOrAlgolia = (query: any, queryID: any) => {
    const hostname = window.location.hostname;

    // Remove the queryID for websites that use the Algolia PLP page
    // This is automatically added by Algolia code, in not using PLP
    // we need to add this for tracking
    // I time, this needs to be the default of /search, but is redirected via Akamai at present
    if (hostname.includes('laithwaites.com') || hostname.includes('laithwaites.com.au')) {
      return `/wines?Ntt=${query}`;
    }

    if (
      hostname.includes('localhost') ||
      hostname.includes('netlify.app') ||
      hostname.includes('laithwaites.co.uk') ||
      hostname.includes('bbcgoodfoodwineclub.com')
    ) {
      return `/search?query=${query}`;
    }

    return `/wines?Ntt=${query}&queryId=${queryID}`;
  };

  /**
   * Fires the Algolia API based on query being available
   * @param {*} query
   * @returns true
   */
  const typeaheadCheck = (query: string) => {
    const url = window.location.href;
    if (query) {
      if (url.includes('laithwaites.co.uk')) {
        return true;
      } else {
        // Ensure we have at least 3 characters in the search
        if (query.length > 2) {
          return true;
        }
      }
    }
    return false;
  };

  const handleSuggestionOpen = (isSuggestionOpen: boolean) => {
    if (isSuggestionOpen) {
      const panelContainer = panelRef.current as any;
      const panelContainerBottom = Math.ceil(panelContainer?.getBoundingClientRect().bottom);
      const aaPanelTop = setSearchOverLay() - panelContainerBottom - 1;
      setSuggestionPanelTop(aaPanelTop);
    }
  };

  /**
   * Retrieve the customer ID and pass to Algolia
   */
  useEffect(() => {
    // Check if the window object is available
    if (typeof window !== 'undefined') {
      if (userDetails?.brandAccountNumber) {
        aa('setUserToken', userDetails?.brandAccountNumber);
      }
    }
  }, [userDetails?.brandAccountNumber]);

  /**
   * Handles older Endeca clicks and conversion
   */
  useEffect(() => {
    const aa = require('search-insights');
    aa('init', {
      appId: globals.algoliaAppId,
      apiKey: globals.algoliaSearchKey,
    });

    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    const queryId = searchParams.get('queryId');

    if (
      typeof window.dataLayer !== 'undefined' &&
      window.dataLayer.length > 0 &&
      typeof window.dataLayer[0].customerNumber !== 'undefined'
    ) {
      const userId = window.dataLayer[0].customerNumber;
      aa('setUserToken', userId);
    }

    const handleClick = (e: any) => {
      const eventName = e.target.getAttribute('data-event-name');
      const itemCode = e.target.getAttribute('data-itemcode');
      const position = e.target.getAttribute('data-position');

      if (queryId) {
        aa('clickedObjectIDsAfterSearch', {
          index: globals.algoliaIndexName,
          eventName,
          queryID: queryId,
          objectIDs: [itemCode],
          positions: [+position],
        });
      }
    };

    const handleConverted = (e: any) => {
      const itemCode = e.detail.itemcode;
      if (queryId) {
        aa('convertedObjectIDsAfterSearch', {
          index: globals.algoliaIndexName,
          eventName: 'Algolia Added to Basket',
          queryID: queryId,
          objectIDs: [itemCode],
        });
      }
    };

    const buttons = document.querySelectorAll('.js-algolia-click-objects');

    buttons.forEach(btn => {
      btn.addEventListener('click', handleClick);
    });

    document.addEventListener('item-added-into-cart-successful', handleConverted);

    return () => {
      buttons.forEach(btn => {
        btn.removeEventListener('click', handleClick);
      });

      document.removeEventListener('item-added-into-cart-successful', handleConverted);
    };
  }, []);

  const plugins = useMemo(() => {
    interface GetItemsParams {
      setContext: (context: any) => void;
      query: string;
    }

    const recentSearches = createLocalStorageRecentSearchesPlugin({
      key: 'RECENT_SEARCH',
      limit: 5,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      transformSource({ source, state }: TransformSourceParams) {
        if (state.query) {
          return null; // Return null if query exists
        }

        return {
          ...source,
          onSelect({ item, state }: OnSelectParams<BaseItem>) {
            const redirectionURL = redirectToEndecaOrAlgolia(item.label, state.context.queryID);
            if (state.query) {
              window.location.href = redirectionURL;
            }
          },
          templates: {
            ...source.templates,
            onSelect({ item }: OnSelectParams<BaseItem>) {
              setInstantSearchUiState({
                query: item.label,
                category: item.category,
              } as any);
            },
            header() {
              return (
                <Fragment>
                  <span className="aa-SourceHeaderTitle">Recent searches</span>
                </Fragment>
              );
            },
          },
        };
      },
    });

    let queryFilters = '';

    if (globals.country === 'us') {
      queryFilters = `threeTierStates: '${currentState}' OR wineryDirectStates: '${currentState}'`;
    }

    const productsPlugin = {
      getSources({ query }: GetSourcesParams<BaseItem>) {
        if (!query) {
          return [];
        }
        if (typeaheadCheck(query)) {
          return [
            {
              sourceId: 'products',
              getItems({ setContext, query }: GetItemsParams) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: globals.algoliaIndexName as string,
                      query,
                      params: {
                        clickAnalytics: true,
                        hitsPerPage: resultsCustomConfig().hitsPerPage,
                        attributesToSnippet: ['name:10', 'Description:20'],
                        snippetEllipsisText: '…',
                        filters: queryFilters,
                      },
                    },
                  ],
                  transformResponse({ hits, results }) {
                    // We want to setup data to pass to the onSubmit function if only one result is returned.
                    // This allows us to redirect to the product page rather than search
                    if (hits[0].length === 1) {
                      const skuItemCodes = (hits[0][0] as ExtendedAlgoliaSearchResult)?.skuItemCodes || [];
                      const productName = (hits[0][0] as ExtendedAlgoliaSearchResult)?.productName || '';
                      const vintage = (hits[0][0] as ExtendedAlgoliaSearchResult)?.vintage || '';
                      const giftFlag = (hits[0][0] as ExtendedAlgoliaSearchResult)?.giftFlag || false;
                      setContext({ skuItemCodes, productName, vintage, giftFlag });
                    } else {
                      setContext({ skuItemCodes: [], productName: '', vintage: '', giftFlag: false });
                    } // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setContext({ queryID: results[0].queryID });

                    if (globals.country === 'us') {
                      const filter = hits?.[0];

                      if (filter.length > 5) {
                        return filter.slice(0, 5);
                      }

                      return filter;
                    }
                    return hits;
                  },
                });
              },
              onSelect({ item }: OnSelectParams<BaseItem>) {
                window.location.href = `${productLinkBuilder(
                  item?.productName,
                  item?.vintage,
                  item?.itemCode,
                  item.giftFlag as boolean,
                )}?queryId=${item.__autocomplete_queryID}`;
              },
              templates: {
                header() {
                  return (
                    <Fragment>
                      <span className="aa-SourceHeaderTitle">Products</span>
                    </Fragment>
                  );
                },
                item({ item, components }: any) {
                  return <ProductItem hit={item} components={components} />;
                },
                noResults() {
                  return 'No products for this query.';
                },
              },
            },
          ];
        } else {
          return [];
        }
      },
    };

    const querySuggestionsPlugin = {
      getSources({ query }: GetSourcesParams<BaseItem>) {
        if (!query) {
          return [];
        }
        if (typeaheadCheck(query)) {
          return [
            {
              sourceId: 'querySuggestionsPlugin',
              getItems({ setContext, query }: GetItemsParams) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: globals.algoliaQuerySuggestions as string,
                      query,
                      params: {
                        clickAnalytics: true,
                        hitsPerPage: 5,
                      },
                    },
                  ],
                  transformResponse({ hits, results }) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setContext({ queryID: results[0].queryID });

                    /*
                  We can add a custom filter to remove items based on an attribute
                  const filter = hits?.[0].filter((hit) => hit.Country !== 'Great Britain');
                  */

                    return hits;
                  },
                });
              },
              onSelect({ item, state }: OnSelectParams<BaseItem>) {
                const redirectionURL = redirectToEndecaOrAlgolia(item.query, state.context.queryID);
                if (state.query) {
                  window.location.href = redirectionURL;
                }
              },
              templates: {
                header() {
                  return (
                    <Fragment>
                      <span className="aa-SourceHeaderTitle">Popular searches</span>
                    </Fragment>
                  );
                },
                item({ item, components }: any) {
                  return (
                    <div className="aa-ItemWrapper">
                      <div className="aa-ItemContent">
                        <div className="aa-ItemContentBody">
                          <div className="aa-ItemContentTitle">
                            {components.Highlight({
                              hit: item,
                              attribute: 'query',
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                },
              },
            },
          ];
        } else {
          return [];
        }
      },
    };

    const redirectUrlPlugin = createRedirectUrlPlugin();

    return [recentSearches, querySuggestionsPlugin, productsPlugin, redirectUrlPlugin];
  }, [currentCategory, currentState]);

  const cancelSearch = () => {
    autocompleteInstance.current?.refresh();
    autocompleteInstance.current?.setQuery('');
    onCancelSearch?.();
  };

  //Adobe DataLayer Click Events
  const recentSearch = () => {
    handleDLClickEvent('recentSearch', 'search');
  };
  const popularSearch = () => {
    handleDLClickEvent('popularSearch', 'search');
  };
  const suggestedProducts = () => {
    handleDLClickEvent('suggestedProducts', 'search');
  };
  //Adobe DataLayer Click Events

  const overlayStyles = css`
    ${theme.widgets.Search?.searchOverlay};
  `;

  const viewAllItemsLink = (
    products: AutocompleteCollection<BaseItem> | undefined,
    html: HTMLTemplate,
    query: string,
  ) => {
    if (products && products.items && products.items.length > resultsCustomConfig().viewAllItemsLength) {
      return html`
        <div class="view-all-items-container">
          <a href="/wines?Ntt=${query}">See all results</a>
        </div>
      `;
    }
  };

  useEffect(() => {
    if (!autocompleteContainer.current || !panelRef.current) {
      return;
    }
    autocompleteInstance.current = autocomplete({
      ...autocompleteProps,
      container: autocompleteContainer.current,
      panelContainer: panelRef.current,
      placeholder: 'Search for products',
      detachedMediaQuery: 'none',
      openOnFocus: true,
      insights: true,
      plugins,
      onReset() {
        setInstantSearchUiState({ query: '' });
      },
      onSubmit({ state }) {
        const redirectionURL = redirectToEndecaOrAlgolia(state.query, state.context.queryID);

        // data click event to AdobeLayer
        handleDLClickEvent('search', 'search');

        if (state?.query && (state?.context?.skuItemCodes as string[])?.includes(state.query)) {
          // window.location.href = `/product/${state.context.productName}/${state.query}`;
          window.location.href = `${productLinkBuilder(
            state.context.productName,
            state.context.vintage,
            state.query,
            state.context.giftFlag as boolean,
          )}`;
        } else {
          window.location.href = redirectionURL;
        }
      },
      // Update the state of the search results as we type, currently disabled
      onStateChange({ state }) {
        handleSuggestionOpen(state.isOpen);
        //   if (prevState.query !== state.query) {
        //     setInstantSearchUiState({
        //       query: state.query,
        //     });
        //   }
      },
      initialState: {
        query: instantSearchUiState?.query,
      },
      render({ elements, render, html, state }, root) {
        const { products, querySuggestionsPlugin, recentSearchesPlugin } = elements;
        /**
         * Get the item length for the product index, so we can ad a link if item
         * length is greater than 10
         */
        const productsArray = state.collections.find(item => item.source.sourceId === 'products');
        render(
          <div css={autocompletePanel} className="aa-PanelLayout aa-Panel--scrollable">
            <div className="ui-container">
              <div className="ui-plugins">
                <div id="query-suggestions" onClick={popularSearch}>
                  {querySuggestionsPlugin}
                </div>
                <div id="recent-searches" onClick={recentSearch}>
                  {recentSearchesPlugin}
                </div>
              </div>
              <div className="ui-products" onClick={suggestedProducts}>
                <div>{products}</div>
                <div>{viewAllItemsLink(productsArray, html, state.query)}</div>
              </div>
            </div>
          </div>,
          root,
        );
      },
      renderer: { createElement, Fragment, render } as AutocompleteRenderer,
    });

    return () => autocompleteInstance.current?.destroy();
  }, [plugins]);

  const panelContainerStyle = css`
    .aa-Panel {
      position: fixed;
      top: 112px !important;
    }
    ${theme.breakpoints.lg} {
      position: relative;
      top: 0;
      .aa-Panel {
        position: absolute;
        top: ${suggestionPanelTop}px !important;
        left: 0 !important;
        width: calc(100% + 400px) !important;
      }
    }
  `;
  return (
    <>
      <div className="search-overlay" css={overlayStyles} onClick={cancelSearch}></div>
      <Grid align="center" className="searchbar-grid">
        <Grid.Col className="w-100">
          <div className="searchbar-input-container">
            <div css={wrapper} ref={autocompleteContainer} />
            <span className={isSticky ? 'isSearchBar' : 'prefix-icon'}>
              <Icon kind="search" size="s" />
            </span>
          </div>
        </Grid.Col>
        <Grid.Col
          xs="auto"
          className={isSticky ? 'isStickyCancelContainer searchbar-cancel-container' : 'searchbar-cancel-container'}
        >
          {onCancelSearch && (
            <Button
              className={isSticky ? 'isStickyCancelButton searchbar-cancel-link' : 'searchbar-cancel-link'}
              onClick={cancelSearch}
            >
              Cancel
            </Button>
          )}
        </Grid.Col>
      </Grid>
      <div id="panel-container" css={panelContainerStyle} ref={panelRef} />
    </>
  );
}
