import { Icon } from '../Icon/Icon';
import { Button } from 'common-ui';

export const SlickLeftArrow = ({ className, style, onClick }: any) => {
  return (
    <Button style={{ ...style }} onClick={onClick} className={className}>
      <Icon kind="chevron-left" size="l" />
    </Button>
  );
};
