import { useInitCartState } from '../atoms/cartAtom';
import { useInitUserState } from '../atoms/userAtom';
import { useInitNotificationsState } from '../atoms/notificationsAtom';

export const useInitCoreAppState = () => {
  const initCartState = useInitCartState();
  const initUserState = useInitUserState();
  const initNotificationsState = useInitNotificationsState();

  return async () => {
    //await user data as we need to know login status BEFORE we can init the cart
    await initUserState();
    initCartState();
    initNotificationsState();
  };
};
