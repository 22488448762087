import React, { useState, useRef } from 'react';
import { css, useTheme } from '@emotion/react';
import { ExpanderEntryType } from 'tsconfig/types';

export type CmsExpanderProps = {
  children: React.ReactNode;
  entryTitle?: ExpanderEntryType['fields']['entryTitle'];
  expanderTitle?: ExpanderEntryType['fields']['expanderTitle'];
  closerTitle?: ExpanderEntryType['fields']['closerTitle'];
  backgroundColor?: ExpanderEntryType['fields']['backgroundColor'];
  titleColor?: ExpanderEntryType['fields']['titleColor'];
};

export const CmsExpander = (props: CmsExpanderProps) => {
  const { expanderTitle, closerTitle, children, backgroundColor, titleColor } = props;
  const theme = useTheme();
  const [collapsed, setCollapsed] = useState(true);
  const [buttonText, setButtonText] = useState(expanderTitle);
  const expanderRef = useRef(null);
  const expanderContent = useRef<HTMLDivElement | null>(null);

  const handleClick = () => {
    setButtonText(collapsed ? closerTitle : expanderTitle);
    if (expanderContent.current) {
      expanderContent.current.style.maxHeight = collapsed ? expanderContent.current.scrollHeight + 'px' : '0px';
    }

    setCollapsed(!collapsed);
  };

  const expanderCss = css`
    ${theme.widgets.CmsExpander?.wrapper};
  `;
  const contentCss = css`
    background: ${backgroundColor
      ? (theme.colors.bgColors as unknown as Record<string, string>)[backgroundColor]
      : 'initial'};
    background-color: ${backgroundColor};
  `;
  const buttonCss = css`
    color: ${titleColor};
    border-bottom: 1px solid ${titleColor};
  `;

  return (
    <div data-test="widget-expander" css={expanderCss} ref={expanderRef}>
      <div className="expander-content" ref={expanderContent} css={contentCss}>
        {children}
      </div>
      <div className="expander-action">
        <button onClick={handleClick} css={buttonCss}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};
