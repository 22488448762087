import { css, useTheme } from '@emotion/react';
import { BannerProps } from 'tsconfig/types';
import styled from '@emotion/styled';
import { Button, inspectorMode } from 'common-ui';
import { fontCorrectionInRichText } from '../../utils/fontCorrectionInRichText';

export const BannerWithContentAndCTA = ({ contentItem }: BannerProps) => {
  const { CTALink, contentBackgroundColour, bannerContent, alignment } = contentItem.fields || {};

  const theme = useTheme();

  const wrapper = css`
  ${theme.widgets.CmsBanner?.wrapper};
    background-color: ${contentBackgroundColour};
    .banner-info {
      text-align: ${alignment?.toLowerCase()};
      .banner-button {
        justify-content: ${alignment?.toLowerCase()};
          }
        }
      }
    }
  `;
  const widgetTheme = theme.widgets.CmsBanner || {};
  const Section = styled.section`
    ${widgetTheme.wrapper}
  `;

  return (
    <div css={wrapper} className="bannerWithContentandCTA">
      <div className="banner-info">
        <Section className="banner-content">
          {bannerContent && /<[a-z][\s\S]*>/i.test(bannerContent) && (
            <div
              dangerouslySetInnerHTML={{ __html: fontCorrectionInRichText(bannerContent, theme.fonts) }}
              {...inspectorMode(contentItem?.sys?.id, 'bannerContent')}
            />
          )}
        </Section>

        {CTALink && (
          <div className="banner-button">
            {CTALink.map((value: any, index: any) => (
              <a href={value?.fields?.url} key={index}>
                <Button
                  children={value?.fields?.title}
                  className={
                    value?.fields?.variant === 'Secondary button'
                      ? 'darkColor'
                      : value?.fields?.variant === 'Secondary-inverted button'
                        ? 'lightColor'
                        : ''
                  }
                  kind={'tertiary'}
                  {...inspectorMode(value?.sys?.id, 'title')}
                />
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
