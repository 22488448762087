export const Noto_Serif = () => ({ style: { fontFamily: 'Noto Serif' } });

export const Roboto = () => ({ style: { fontFamily: 'Roboto' } });

export const Oswald = () => ({ style: { fontFamily: 'Oswald' } });

export const Crimson_Text = () => ({ style: { fontFamily: 'Crimson Text' } });

export const Arsenal = () => ({ style: { fontFamily: 'Arsenal' } });

export const Libre_Caslon_Text = () => ({ style: { fontFamily: 'Libre Caslon Text' } });

export const Playfair_Display = () => ({ style: { fontFamily: 'Playfair Display' } });

export const PT_Sans = () => ({ style: { fontFamily: 'PT Sans' } });

export const Lato = () => ({ style: { fontFamily: 'Lato' } });

export const Montserrat = () => ({ style: { fontFamily: 'Montserrat' } });

export const LocalFont = (fontAssets: any) => {
  // Extract the filename from the path
  const filename = fontAssets.src[0]?.path.split('/').pop();

  // Extract the font name from the filename
  const fontName = filename?.split('.')[0]?.split('-')?.shift() || '';

  // Return the style object with fontFamily
  return {
    style: { fontFamily: fontName },
  };
};
