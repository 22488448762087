import { css, useTheme } from '@emotion/react';
import type { MemoExoticComponent, ReactNode, Ref } from 'react';
import React, { memo } from 'react';
import { Align, AlignProps, Justify, JustifyProps, FlexDirection } from 'tsconfig/types';
import { generateUtilCss } from '../../utils/grid.utils';
import Col from '../col/Col';

type GridProps = {
  /**
   * The CSS `align-items` property
   */
  align?: Align | AlignProps;

  /**
   * The CSS `justify-content` property
   */
  justify?: Justify | JustifyProps;

  /**
   * Remove the gutter(padding) from `Cols`
   */
  noGutter?: boolean;
  direction?: FlexDirection;
  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;

  /**
   * The children nodes
   */
  children: ReactNode;

  /**
   * custom class
   */
  className?: string;
  /**
   * unit and e2e selector attribute
   */
  'data-test'?: string;
  /**
   * on key down event
   */
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
  /**
   * on click event
   */
  onClick?: () => void;
};

type UtilPropType = Pick<GridProps, 'align' | 'justify'>;

type GridWithCol = MemoExoticComponent<(props: GridProps) => JSX.Element> & { Col: typeof Col };

const GridComponent = ({
  className,
  noGutter,
  align,
  direction,
  justify,
  'data-test': dataTest,
  ...props
}: GridProps) => {
  const theme = useTheme();

  const justifyCss = generateUtilCss<UtilPropType['justify']>(justify, 'justify', theme);
  const alignCss = generateUtilCss<UtilPropType['align']>(align, 'align', theme);

  return (
    <div
      className={className}
      data-test={dataTest}
      css={css([theme.mixins.grid, noGutter && theme.mixins.noGutter, justify && justifyCss, align && alignCss])}
      {...props}
      style={{ flexDirection: direction }}
    >
      {props.children}
    </div>
  );
};

const Grid = memo(GridComponent) as GridWithCol;

Grid.Col = Col;

export default Grid;
