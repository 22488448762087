import { Skeleton } from '@mantine/core';
import { css, useTheme } from '@emotion/react';

export const ProductCardSkeleton = () => {
  const theme = useTheme();
  const productCardSkeleton = css`
    ${theme.widgets.Product?.productCardSkeleton}
  `;

  return (
    <div css={productCardSkeleton} data-test="productCardSkeleton">
      <Skeleton height="366" mt={0} radius="sm" />
      <div className="product-title">
        <Skeleton height={20} mt={8} width="300" radius="sm" />
        <Skeleton height={20} mt={10} width="100" radius="sm" />
        <div className="product-rating">
          <Skeleton height={10} mt={0} width="190" radius="sm" />
        </div>
        <div className="product-price">
          <Skeleton height={10} mt={6} width="140" radius="sm" />
        </div>
        <div className="product-add">
          <Skeleton height={56} mt={20} width="170" radius="sm" />
        </div>
      </div>
    </div>
  );
};
