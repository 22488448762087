import { globals } from 'common-ui';

export const cmsVideoComponentSchema = (data: { image: any; videoLink: string }) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name: data?.image?.fields?.entryTitle,
    description: data?.image?.fields?.description || data?.image?.fields.largeDeviceImage?.fields?.description,
    thumbnailUrl: data?.image?.fields.largeDeviceImage?.fields?.file?.url,
    uploadDate: data?.image?.fields.largeDeviceImage?.sys?.updatedAt,
    dateCreated: data?.image?.fields.largeDeviceImage?.sys?.createdAt,
    datePublished: data?.image?.fields.largeDeviceImage?.sys?.createdAt,
    genre: 'Wine',
    duration: 'PT1M',
    contentUrl: data?.videoLink,
    publisher: {
      '@type': 'Organization',
      name: 'Laithwaites',
      logo: {
        '@type': 'ImageObject',
        url: `${process.env.NEXT_PUBLIC_DW_URL}/assets/components/headerfooter/${globals.country}/${globals.brandId}/build/next/images/brand-logo.svg`,
      },
    },
    embedUrl: data?.videoLink,
  };
};
