// utils/geolocation.js : Checking US state based on geolocation.

interface stateGeofeed {
  [key: string]: string;
}

function getStateFromZip(statecode: string) {
  const stateZipCodes: stateGeofeed = {
    AL: '35004',
    AK: '99501',
    AZ: '85001',
    AR: '71601',
    CA: '90001',
    CO: '80001',
    CT: '06001',
    DE: '19701',
    FL: '32003',
    GA: '30002',
    HI: '96701',
    ID: '83201',
    IL: '60001',
    IN: '46001',
    IA: '50001',
    KS: '66002',
    LA: '70001',
    ME: '03901',
    MD: '20588',
    MA: '01001',
    MI: '48001',
    MN: '55001',
    MO: '63001',
    MT: '59001',
    NE: '68001',
    NV: '88901',
    NH: '03031',
    NJ: '07001',
    NM: '87001',
    NY: '00501',
    NC: '27006',
    ND: '58001',
    OH: '43001',
    OK: '73001',
    OR: '97001',
    PA: '15001',
    RA: '02801',
    SC: '29001',
    SD: '57001',
    TN: '37010',
    TX: '73301',
    UT: '84001',
    VA: '20101',
    WA: '98001',
    WV: '24701',
    WI: '53001',
    WY: '82001',
  };
  return stateZipCodes[statecode];
}

function addGeolocationScript(cb: (state: string) => void) {
  const geoLocationScript = 'https://geolocation.onetrust.com/cookieconsentpub/v1/geo/location/geofeed';
  if (document?.cookie?.indexOf('shoppinglocation=') === -1) {
    (window as any).geofeed = function (options: stateGeofeed) {
      const country = options.country.toString();
      const state = country === 'US' && options.state.toString() !== '' ? options.state.toString() : 'CT';
      document.cookie = 'shoppinglocation=' + getStateFromZip(state) + '-' + state + ';path=/';
      cb(state);
    };
    if (!document.querySelector(`script[src="${geoLocationScript}"]`)) {
      const script = document.createElement('script');
      script.src = geoLocationScript;
      document.head.appendChild(script);
    }
  }
}

export function getCookie(name: string) {
  if (typeof document === 'undefined') return;
  const value = '; ' + document.cookie;
  const decodedValue = decodeURIComponent(value);
  const parts = decodedValue.split('; ' + name + '=');

  if (parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
}

export function disableStatePopupCookie() {
  if (typeof document === 'undefined') return;
  document.cookie = 'showEmbeddedStatePopup=true;max-age=0';
}

export default addGeolocationScript;
