import { css, useTheme } from '@emotion/react';
import { BannerProps } from 'tsconfig/types';
import { CmsImage, CmsLink, inspectorMode } from 'common-ui';
import { fontCorrectionInRichText } from '../../utils/fontCorrectionInRichText';
import { navigateToBannerLink, stopBannerContentEventPropagation } from '../contentful/CmsBanner/CmsBannerUtility';

export const BannerWithButton = ({ contentItem }: BannerProps) => {
  const { CTALink, contentBackgroundColour, bannerContent, backgroundImage, entryTitle, alignment, bannerLink } =
    contentItem.fields || {};

  const theme = useTheme();
  const wrapper = css`
    background: ${contentBackgroundColour
      ? (theme.colors.bgColors as unknown as Record<string, string>)[contentBackgroundColour]
      : 'initial'};
    background-color: ${contentBackgroundColour};
    ${bannerLink && `cursor: pointer`};
    ${theme.widgets.CmsBanner?.BannerWithButton};
    .banner-content {
      justify-content: center;
      text-align: ${alignment?.toLowerCase()};
      .banner-button {
        margin-top: 10px;
        a {
          margin: 5px;
        }
      }
    }
  `;
  return (
    <div
      css={wrapper}
      className="banner-with-button"
      data-testid="banner-full-width"
      onClick={() => {
        navigateToBannerLink(bannerLink);
      }}
    >
      <div className="banner-content">
        <div className="banner-content_container">
          <h2 {...inspectorMode(contentItem?.sys?.id, 'entryTitle')}>{entryTitle}</h2>
          <h3>
            {bannerContent && /<[a-z][\s\S]*>/i.test(bannerContent) && (
              <div
                className="description"
                onClick={stopBannerContentEventPropagation}
                dangerouslySetInnerHTML={{ __html: fontCorrectionInRichText(bannerContent, theme.fonts) }}
                {...inspectorMode(contentItem?.sys?.id, 'bannerContent')}
              />
            )}
          </h3>
          {CTALink && (
            <div className="banner-button">
              {CTALink.map((value: any, index: any) => (
                <CmsLink key={index} contentItem={value} />
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="banner-img-button">
        <CmsImage fill={true} contentItem={backgroundImage} />
      </div>
    </div>
  );
};
