import { ThemeProvider } from '@emotion/react';
import { AlgoliaSearchInstant } from 'common-ui';
import { RecoilRoot } from 'common-state';
import HeaderFooterWrapper from './HeaderFooterWrapper';
import { CommonEmbeddedHeaderFooterAppProps } from 'tsconfig/types';
import { RecoilInitEmbeddedWrapper } from './RecoilInitEmbeddedWrapper';
import RecoilNexus from 'recoil-nexus';

export function CommonEmbeddedHeaderFooterApp({ theme, GlobalStyles }: CommonEmbeddedHeaderFooterAppProps) {
  return (
    <RecoilRoot>
      <RecoilNexus />
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <RecoilInitEmbeddedWrapper />
        <AlgoliaSearchInstant>
          <HeaderFooterWrapper config={window.newHeaderFooterInitData} />
        </AlgoliaSearchInstant>
      </ThemeProvider>
    </RecoilRoot>
  );
}
