import { DLClickEventProps } from 'tsconfig/types';
import { adobeDataLayer } from './AdobeDataLayer';

export const handleDLClickEvent = (data: string, clickType: string) => {
  //Read the element to get the DL attributes and add to the DL
  const splitContent = data.split(':');
  const lastIndex = splitContent.length - 1;
  const lastText = splitContent[lastIndex];

  function getClickLocation(data: string) {
    switch (data) {
      case 'recentSearch':
        return 'recent searches';
      case 'popularSearch':
        return 'popular searches';
      case 'suggestedProducts':
        return 'suggested products';
      default:
        return 'search box';
    }
  }

  let click: DLClickEventProps = {};
  if (clickType === 'mainNavClick') {
    click = {
      clickDescription: `top nav: ${data}`,
      clickName: 'main navigation click',
      clickLocation: 'main nav',
      clickText: lastText,
      clickAction: 'navigation',
    };
  } else if (clickType === 'topNavClick') {
    click = {
      clickDescription: `top nav: ${data} logo`,
      clickName: 'top navigation click',
      clickLocation: 'top nav',
      clickText: data,
      clickAction: 'navigation',
    };
  } else if (clickType === 'cartIcon') {
    click = {
      clickDescription: 'top nav:cart',
      clickName: 'top navigation click',
      clickLocation: 'top nav',
      clickText: data,
      clickAction: 'shopping cart icon',
    };
  } else if (clickType === 'search') {
    click = {
      clickDescription: 'top nav: search',
      clickName: 'top navigation click',
      clickLocation: getClickLocation(data),
      clickText: 'search',
      clickAction: 'search overlay',
    };
  } else if (clickType === 'loginButton') {
    click = {
      clickDescription: 'top nav:login',
      clickName: 'top navigation click',
      clickLocation: 'top nav',
      clickText: data,
      clickAction: 'login overlay',
    };
  } else if (clickType === 'notYou') {
    click = {
      clickDescription: 'top nav:not you',
      clickName: 'top navigation click',
      clickLocation: 'top nav',
      clickText: data,
      clickAction: 'hard log out',
    };
  } else if (clickType === 'logout') {
    click = {
      clickDescription: `top nav: ${data}`,
      clickName: 'top navigation click',
      clickLocation: 'top nav',
      clickText: data,
      clickAction: 'log out',
    };
  } else if (clickType === 'print') {
    click = {
      clickDescription: 'product:print wine label',
      clickName: 'print',
      clickLocation: 'product',
      clickText: 'print',
      clickAction: 'beyond label overlay',
    };
  } else if (clickType === 'favourite') {
    click = {
      clickDescription: 'product:favourite',
      clickName: 'favourite',
      clickLocation: 'product favourite',
      clickText: 'favourite wine',
      clickAction: 'favourite',
      errorMessage: '',
    };
  } else if (clickType === 'unfavourite') {
    click = {
      clickDescription: 'product:unfavourite',
      clickName: 'unfavourite',
      clickLocation: 'product unfavourite',
      clickText: 'unfavourite wine',
      clickAction: 'unfavourite',
      errorMessage: '',
    };
  } else if (clickType === 'phoneClick') {
    click = {
      clickDescription: `footer nav:tel:${data}`,
      clickName: 'phone link',
      clickLocation: 'footer nav',
      clickText: data,
      clickAction: 'phone',
    };
  } else if (clickType === 'liveChatInteraction') {
    click = {
      clickDescription: 'footer nav:live chat:open',
      clickName: 'live chat',
      clickLocation: 'footer nav',
      clickText: 'Live Chat',
      clickAction: 'open',
    };
  } else if (clickType === 'emailClick') {
    click = {
      clickDescription: 'footer nav:email',
      clickName: 'email link',
      clickLocation: 'footer nav',
      clickText: 'Email',
      clickAction: 'email',
    };
  } else if (clickType === 'accordionMenu') {
    click = {
      clickDescription: `footer nav:${lastText}`,
      clickName: 'footer nav',
      clickLocation: 'footer nav',
      clickText: lastText,
      clickAction: splitContent[0],
    };
  } else if (clickType === 'socialShare') {
    click = {
      clickDescription: `footer nav:social share:${data}`,
      clickName: 'social share',
      clickLocation: 'footer nav',
      clickText: data,
      clickAction: 'social share',
    };
  }
  adobeDataLayer.clickEvent(clickType, click);
};
