// Wrapper for a few edge case end points that dont fit other categories
// e.g. logout jsp pages
//Note: these end points will NOT work in local dev or in Netlify preview deploys
import axios from 'axios';

export const initAtgLegacyMiscApi = () => {
  async function asyncRequest(requestType: string, url: string, data = {}) {
    const requestConfig: any = {
      method: requestType,
      url: url,
      data,
    };
    const response = await axios.request(requestConfig);
    return response.data;
  }

  return {
    User: {
      softLogout: async () => await asyncRequest('GET', '/jsp/templates/page_elements/common/soft_logout.jsp'),
      hardLogout: async () => await asyncRequest('GET', '/jsp/templates/page_elements/common/hard_logout.jsp'),
    },
  };
};
