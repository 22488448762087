import { css, useTheme } from '@emotion/react';
import React, { memo, useState } from 'react';
import { Icon } from '../../../Icon/Icon';
import { LinkEntryType } from 'tsconfig/types';
import { globals } from '../../../../utils/globals';

type HeaderFavouritesProps = {
  cmsFavourite?: LinkEntryType;
};

export const HeaderFavourites = memo((props: HeaderFavouritesProps) => {
  const { cmsFavourite } = props;
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const linkClass = isMouseEnter ? 'icon-hover' : '';
  const theme = useTheme();
  const wrapper = css`
    ${theme.widgets.GlobalHeader?.headerFavourites}
  `;
  return (
    <div data-test="widget-HeaderFavourites" css={wrapper} id="header-favourites">
      {cmsFavourite?.fields?.url && (
        <a
          href={cmsFavourite?.fields?.url}
          className={`${linkClass} favourites-link`}
          onMouseEnter={() => setIsMouseEnter(true)}
          onMouseLeave={() => setIsMouseEnter(false)}
          data-event="headerFavouriteClick"
          data-section="header:favourite"
          data-action="favourite"
          data-name="header favourite"
          data-text="favourite"
        >
          <Icon kind={isMouseEnter ? 'heart-solid' : 'heart'} size="m" />
          {globals?.country !== 'us' && <span>Favourites</span>}
        </a>
      )}
    </div>
  );
});
