import React, { useEffect, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { LoadingOverlay, Modal } from '@mantine/core';
import { css, useTheme } from '@emotion/react';
import { Icon } from '../../Icon/Icon';
import {
  getInventoryLimitCheck,
  getMaxLimitCheck,
  globals,
  TrendingProductItems,
  STATIC_WINE_PATHNAME,
  ITEMCLASSIFICATION_TYPE_GIFT,
} from 'common-ui';
import { useRecoilValue } from 'recoil';
import { selectCartData } from 'common-state';
import { LineItem, MiniCartModalProps } from 'tsconfig/types';
import MiniCartLayout from '../MiniCartLayout';

const MiniCartModal: React.FC<MiniCartModalProps> = ({
  show,
  size = 'xl',
  onClose,
  selectedItemCode,
  qty,
}: MiniCartModalProps) => {
  const theme = useTheme();
  const [opened, { open, close }] = useDisclosure(false);
  const [animationClass, setAnimationClass] = useState('slide-in');
  const cartItems = useRecoilValue(selectCartData);
  const [selectedItem, setSelectedItem] = useState<LineItem[] | undefined>();
  const [isFreeGiftLineItem, setIsFreeGiftLineItem] = useState(false);
  const [isGreatPick, setIsGreatPick] = useState(false);
  const [loadMiniCart, setLoadMiniCart] = useState(false);
  const [showCheckoutButton, setShowCheckoutButton] = useState(false);

  const MiniCartWrapper = css`
    ${theme.widgets?.Cart?.MiniCart || ''}
    .mantine-Modal-content {
      animation: ${animationClass === 'slide-in' ? 'slideInFromRight' : 'slideOutToRight'} 0.2s ease-in-out !important;
    }
  `;

  const handleClose = () => {
    setAnimationClass('slide-out');
    const timer = setTimeout(() => {
      close();
      onClose?.();
    }, 200);
    return () => clearTimeout(timer);
  };

  const Title = () => {
    return (
      <div className="mini-cart-header">
        <Icon kind="tick-circle" size="l" />
        <p className="mini-cart-header-text"> {globals?.country !== 'uk' ? 'IN YOUR CART' : 'ADDED TO BASKET'}</p>
      </div>
    );
  };

  useEffect(() => {
    const item = cartItems?.lineItems?.filter(product => product?.sku?.itemCode === selectedItemCode);
    if (item.length) {
      setSelectedItem(item);
    }

    const inventoryLimitCheck = getInventoryLimitCheck(cartItems?.lineItems);
    const maxLimitCheck = getMaxLimitCheck(cartItems?.lineItems);

    const showCheckoutButton = inventoryLimitCheck || maxLimitCheck;
    setShowCheckoutButton(!showCheckoutButton);
    const freeItem = cartItems?.lineItems?.filter(item =>
      item?.itemClassifications?.some(classification =>
        classification.toLowerCase().includes(ITEMCLASSIFICATION_TYPE_GIFT),
      ),
    );
    if (freeItem?.length > 0) {
      setIsFreeGiftLineItem(true);
    }
  }, [cartItems, selectedItemCode]);

  const handleContinueShoppingClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    handleClose();
  };

  return (
    <>
      {selectedItem?.length && (
        <Modal
          css={MiniCartWrapper}
          opened={opened || !!show}
          className={`mantine-Modal-content-minicart ${animationClass}`}
          onClose={handleClose}
          title={<Title />}
          size={size}
        >
          <div style={{ position: 'relative' }}>
            <LoadingOverlay visible={loadMiniCart} zIndex={1000} loaderProps={{ color: theme.colors.primary1 }} />
            <MiniCartLayout
              lineItems={selectedItem}
              selectedItemCode={selectedItemCode}
              totalLineItem={cartItems?.totalLineItems}
              onClose={handleClose}
              isFreeGiftLineItem={isFreeGiftLineItem}
              isGreatPick={isGreatPick}
              qty={qty}
              showCheckoutButton={showCheckoutButton}
            />
            <TrendingProductItems isMiniCart={true} setIsGreatPick={setIsGreatPick} />
          </div>
          <div className="continue-shopping desktop" onClick={handleClose}>
            <span className="continue-shopping-text">continue shopping</span>
          </div>
          <div className="tax">
            <p className="tax-info">
              {globals.country === 'au'
                ? 'All prices are inclusive of GST. Any additional discounts will be displayed at checkout.'
                : globals.country === 'uk'
                  ? 'Any additional discounts will display at checkout.'
                  : 'Applicable tax, shipping and any additional discounts will display at checkout'}
            </p>
          </div>
        </Modal>
      )}
    </>
  );
};

export default MiniCartModal;
