import { InstantSearch, Configure, InstantSearchSSRProvider, InstantSearchServerState } from 'react-instantsearch';
import React, { ReactNode } from 'react';
import { algoliaClient } from './algoliaSearchConfig';
import { globals } from '../../utils/globals';
export type AlgoliaSearchInstantProps = {
  serverState?: InstantSearchServerState;
  children: ReactNode;
};

const searchClient = {
  ...algoliaClient,
  search(requests: any[]) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: '',
          params: '',
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

export const AlgoliaSearchInstant = ({ serverState, children }: AlgoliaSearchInstantProps): JSX.Element => {
  if (globals?.algoliaIndexName) {
    return (
      <InstantSearchSSRProvider {...serverState}>
        <InstantSearch
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          searchClient={searchClient}
          indexName={`${globals.algoliaIndexName}`}
          insights={true}
          future={{
            preserveSharedStateOnUnmount: true,
          }}
        >
          <Configure
            // filters= "country: 'New Zealand'"
            // analyticsTags={['YourAnalyticsTags']}
            clickAnalytics
          />
          {children}
        </InstantSearch>
      </InstantSearchSSRProvider>
    );
  }
  return <>{children}</>;
};
