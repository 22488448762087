import React, { useEffect, useState } from 'react';
import {
  Button,
  BottleCount,
  Icon,
  NEW_CART_PAGE,
  OLD_CART_PAGE,
  globals,
  useCheckoutRedirect,
  Link,
  smallProductImageUrl,
  largeProductImageUrl,
} from 'common-ui';
import Image from 'next/image';
import { productLinkBuilder } from 'common-next/src/components/common-functions';
import { formatCurrency } from 'common-ui/src/utils/formatCurrency';
import { LineItem, MiniCartLayoutProps } from 'tsconfig/types';

export const getGiftAndDepositeCardImage = (brandId: string, cardType: string) => {
  if (brandId === 'law') {
    brandId = 'lw';
  }
  let url = `${globals.publicHost}/images/us/en/product/${brandId}-giftcard.png`;
  if (cardType === 'DEPOSIT') {
    url = `${globals.publicHost}/images/us/en/product/${brandId}-initialdeposit.png`;
  }
  return url;
};

export const checkIsUnlimitedItem = (lineItems: LineItem[]): boolean => {
  return lineItems.some(function (item) {
    const schemeId = item?.sku?.schemeDetails?.[0]?.schemeId?.toLowerCase();
    if (!schemeId) return false;
    return /ul|unl|unt/.test(schemeId);
  });
};

const MiniCartLayout: React.FC<MiniCartLayoutProps> = ({
  lineItems,
  totalLineItem,
  onClose,
  isFreeGiftLineItem,
  isGreatPick,
  qty,
  showCheckoutButton,
}) => {
  const redirectCheckoutRoute = useCheckoutRedirect();

  const defaultImageSrc = `${globals.publicHost}${globals.defaultImagePath}/${
    lineItems[0]?.product?.productType === 'Case' ? 'default_case' : 'default_bottle'
  }.png`;

  const [imageSrc, setImageSrc] = useState<string>(defaultImageSrc);

  const productHref = productLinkBuilder(
    lineItems[0]?.sku?.productName[0]?.value,
    lineItems[0]?.product?.vintage || '',
    lineItems[0]?.sku?.itemCode,
    false,
  );

  useEffect(() => {
    // TODO: Need to update this once AWS cart API is implemented for US

    const getProductImageUrl = globals.country !== 'uk' ? largeProductImageUrl : smallProductImageUrl;
    let newImageSrc = getProductImageUrl(lineItems[0]?.product?.itemCode ?? '');

    if (globals.country !== 'uk') {
      const productType = lineItems[0]?.product?.productType.toLowerCase();
      if (globals.country === 'au' && checkIsUnlimitedItem(lineItems)) {
        newImageSrc = `${globals.publicHost}/images/au/common/logos/unlimited_logo.svg`;
      } else if (productType === 'voucher') {
        newImageSrc = getGiftAndDepositeCardImage((globals?.brandId as string) ?? '', 'GIFT');
      } else if (productType === 'service' && lineItems[0]?.itemClassifications?.includes('DEPOSIT_ITEM')) {
        newImageSrc = getGiftAndDepositeCardImage((globals?.brandId as string) ?? '', 'DEPOSIT');
      }
    }

    setImageSrc(newImageSrc);
  }, [lineItems]);

  const handleRedirectionClick = (e: React.MouseEvent<HTMLAnchorElement>, href: string) => {
    const currentUrl = window.location.pathname;
    if (currentUrl === href) {
      e.preventDefault();
    }
    onClose();
  };

  const handleImageError = () => {
    setImageSrc(defaultImageSrc);
  };

  const redirectLink = globals?.useNewCartAPI ? NEW_CART_PAGE : OLD_CART_PAGE;

  const VIEW_CART_TEXT = globals?.country !== 'uk' ? 'cart' : 'basket';
  const message = `You ${globals?.country === 'us' ? 'qualify for' : 'got'} a free gift`;

  const renderGift = (className: string) => {
    return (
      <div className={className}>
        <Icon kind="gift" size="s" />
        <h4>
          <span>{message}</span>
          <Link
            href={globals?.useNewCartAPI ? NEW_CART_PAGE : OLD_CART_PAGE}
            className="cart-link light-grey"
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => handleRedirectionClick(e, redirectLink)}
          >
            {`View in ${VIEW_CART_TEXT}`}
          </Link>
        </h4>
      </div>
    );
  };

  const ViewCart = () => {
    return (
      <Button
        kind="secondary"
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => handleRedirectionClick(e, redirectLink)}
        className="view-basket"
      >
        VIEW {VIEW_CART_TEXT.toUpperCase()} ({totalLineItem} ITEM{totalLineItem > 1 && 'S'})
      </Button>
    );
  };

  return (
    <>
      <div className="wrapper">
        <div className="product-image">
          <Image
            src={imageSrc}
            alt={lineItems[0]?.sku?.productName[0]?.value || ''}
            sizes="100vw"
            width={216}
            height={216}
            unoptimized
            onError={handleImageError}
            style={{ objectFit: 'scale-down' }}
          />
        </div>
        <div className="product-details-container">
          <p className="product-great-pick">{isGreatPick ? 'Another Great Pick!' : 'Great Pick!'}</p>
          <div className="product-detail-text">
            {lineItems[0]?.product?.productType?.toLowerCase() !== 'service' ? (
              <Link
                href={productHref}
                className="product-link"
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => handleRedirectionClick(e, productHref)}
              >
                <span className="checkout-item-qty">{globals?.country !== 'uk' ? lineItems[0]?.quantity : qty}× </span>
                {lineItems[0]?.sku?.productName[0]?.value} {lineItems[0]?.product?.vintage || ''}
              </Link>
            ) : (
              <div className="product-link">
                {lineItems[0]?.sku?.productName[0]?.value} {lineItems[0]?.product?.vintage || ''}
              </div>
            )}
          </div>
          <BottleCount
            productType={
              lineItems[0]?.product?.skus?.[0]?.referencedSkus?.[0]?.expandedItem?.productType
                ? lineItems[0]?.product?.skus?.[0]?.referencedSkus?.[0]?.expandedItem?.productType
                : lineItems[0]?.product?.productType
            }
            bottleType={lineItems[0]?.product?.bottleType}
            numberOfBottles={lineItems[0]?.sku?.numberOfBottles}
          />
          <div className="price-section">
            {globals?.country !== 'uk' ? (
              <>
                {lineItems[0]?.savings?.totalProductDiscountSavings > 0 && (
                  <p className="price strike">
                    {formatCurrency(lineItems[0]?.unitPrice?.listPrice?.amount * lineItems[0]?.quantity)}
                  </p>
                )}
                <p
                  className={`price sale-price ${lineItems[0]?.unitPrice?.priceType === 'MemberPrice Discount' ? 'member-price' : ''}`}
                >
                  {formatCurrency(lineItems[0]?.unitPrice?.value?.amount)}
                </p>
              </>
            ) : (
              <p className="price">{formatCurrency(lineItems[0]?.unitPrice?.value?.amount * Number(qty))}</p>
            )}
          </div>
          {globals?.country !== 'uk' && lineItems[0]?.unitPrice?.priceType === 'MemberPrice Discount' && (
            <p className="member-savings">
              {formatCurrency(lineItems[0]?.savings?.totalProductDiscountSavings)}{' '}
              {globals.brandId === 'wsj' ? 'ADVANTAGE' : 'UNLIMITED'} MEMBER SAVINGS
            </p>
          )}
          {isFreeGiftLineItem && renderGift('gift-tablet')}
        </div>
      </div>

      {isFreeGiftLineItem && renderGift('gift-other-device')}
      <div className="button-container">
        <Link href={globals?.useNewCartAPI ? NEW_CART_PAGE : OLD_CART_PAGE} prefetch={false}>
          <ViewCart />
        </Link>
        {showCheckoutButton && (
          <a href={redirectCheckoutRoute}>
            <Button
              kind="primary"
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => handleRedirectionClick(e, redirectCheckoutRoute)}
            >
              CHECKOUT
            </Button>
          </a>
        )}
      </div>

      <div className="continue-shopping mobile" onClick={onClose}>
        <span className="continue-shopping-text">continue shopping</span>
      </div>
    </>
  );
};

export default MiniCartLayout;
